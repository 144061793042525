import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { GetOneJob, SubmitJobWarranty } from "../../actions/jobs";
import toast from "react-hot-toast";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import * as _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import { Input, Modal, Select } from "antd";
import { GetWarrantyProviders } from "../../actions/customers";
import { Helmet } from "react-helmet-async";

const JobWarranty = ({ authState, authDispatch }) => {
  let [loading, setLoading] = useState(true);
  let [job, setJob] = useState({});
  let [warrantyModal, setWarrantyModal] = useState(false);
  let [customers, setCustomers] = useState([]);
  let [warrantyData, setWarrantyData] = useState({
    warrantyProvider: "",
    warrantyProviderId: "",
    claimNo: "",
  });

  const { jobId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      reloadData();
    }
    return () => {
      inView = false;
    };
    // eslint-disable-next-line
  }, []);

  const reloadData = () => {
    GetOneJob(jobId)
      .then((res) => {
        setJob(res.data.job);
        if (res.data.job.warranty) {
          setWarrantyData(res.data.job.warrantyInformation);
        }
        GetWarrantyProviders()
          .then((res) => {
            let toPush = [];
            for (let i = 0; i < res.data.length; i++) {
              const element = res.data[i];
              toPush.push({
                value: element.customerId,
                label: element.customerCode + (element.company.length > 0 && " - " + element.company),
              });
            }
            setCustomers(toPush);
            setTimeout(() => setLoading(false), 700);
          })
          .catch((err) => {
            toast.error(err.response && err.response.data ? err.response.data.message : "Error loading job information");
            setTimeout(() => {
              navigate("/jobs");
            }, 2500);
          });
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
        setTimeout(() => {
          navigate("/jobs");
        }, 3000);
      });
  };

  const tabs = [
    { name: "Info", href: "#", current: false },
    { name: "Parts", href: "parts", current: false },
    { name: "Labor", href: "labor", current: false },
    { name: "Parking", href: "parking", current: false },
    { name: "Loadbank", href: "loadbank", current: false },
    { name: "Freight", href: "freight", current: false },
    { name: "Misc", href: "misc", current: false },
    { name: "POs", href: "purchaseOrders", current: false },
    {
      name: "ITs",
      href: "inventoryTransmittals",
      current: false,
    },
    { name: "Equip.", href: "equipment", current: false },
    { name: "Tests", href: "tests", current: false },
    { name: "Warranty", href: "warranty", current: true },
    { name: "Payments", href: "payments", current: false },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const closeWarrantyModal = () => {
    setWarrantyModal(false);
    setWarrantyData({
      warrantyProvider: "",
      warrantyProviderId: "",
      claimNo: "",
    });
  };

  const openWarranty = () => {
    let failed = false;
    if (warrantyData.warrantyProviderId.length === 0) {
      failed = true;
      toast.error("Please select a warranty provider");
    }
    if (warrantyData.warrantyProvider.length === 0) {
      failed = true;
      toast.error("Please select a warranty provider");
    }
    let tmp = warrantyData;
    if (tmp.claimNo === "" || !tmp.claimNo) {
      tmp.claimNo = "";
      toast("Remember to enter claim information before submitting the job for invoicing");
    }
    if (!failed) {
      setLoading(true);
      SubmitJobWarranty(jobId, tmp)
        .then((res) => {
          toast.success("Warranty information submitted successfully");
          closeWarrantyModal();
          reloadData();
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error submitting warranty information");
          setLoading(false);
        });
    }
  };

  const updateProvider = (v) => {
    let tmp = warrantyData;
    let provider = customers.find((c) => c.value === v);
    tmp.warrantyProvider = provider.label;
    tmp.warrantyProviderId = provider.value;
    setWarrantyData(tmp);
  };

  const updateWarranty = () => {
    let failed = false;
    if (warrantyData.warrantyProviderId.length === 0) {
      failed = true;
      toast.error("Please select a warranty provider");
    }
    if (warrantyData.warrantyProvider.length === 0) {
      failed = true;
      toast.error("Please select a warranty provider");
    }
    let tmp = warrantyData;
    if (tmp.claimNo === "" || !tmp.claimNo) {
      tmp.claimNo = "";
      toast("Remember to enter claim information before submitting the job for invoicing");
    }
    if (!failed) {
      setLoading(true);
      SubmitJobWarranty(jobId, tmp)
        .then((res) => {
          toast.success("Warranty information submitted successfully");
          closeWarrantyModal();
          reloadData();
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error submitting warranty information");
          setLoading(false);
        });
    }
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
        </div>
      ) : (
        <>
          <Helmet>
            <title>Warranty - {job.jobNo} | HTPS ERP</title>
          </Helmet>
          <div className="flex flex-row items-center justify-start w-full mb-3">
            <div className="w-full sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              <select
                id="tabs"
                name="tabs"
                className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:border-blue-700 focus:outline-none focus:ring-blue-700 sm:text-sm"
                defaultValue={tabs.find((tab) => tab.current).href}
                onChange={(v) => navigate(`/jobs/${jobId}/${v.target.value}`)}
              >
                {tabs.map((tab) => (
                  <option value={tab.href}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden w-full sm:block">
              <div className="border-b border-gray-200">
                <nav className="flex -mb-px space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <p
                      value={tab.name}
                      key={uuidv4()}
                      onClick={() => navigate(`/jobs/${jobId}/${tab.href}`)}
                      className={classNames(
                        tab.current ? "border-blue-700 text-blue-700" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer",
                      )}
                      aria-current={tab.current ? "page" : undefined}
                    >
                      {tab.name}
                    </p>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-start w-full px-1 mt-1">
            <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
              <p className="text-xl font-bold uppercase">Job Warranty</p>
            </div>
            <div className="flex flex-col items-center justify-start w-full gap-2 mt-4 text-black">
              {!job.warranty ? (
                <div className="flex flex-col items-center justify-center gap-4 px-20 py-6 bg-white border border-gray-200 shadow-lg rounded-xl">
                  <h3 className="text-xl font-bold">Is this a warranty job?</h3>
                  <p className="max-w-lg font-medium text-center text-gray-500">
                    Add a warranty claim to this job, in order to enter the information about the warranty provider and the claim number here.
                  </p>
                  <PrimaryButton label="Add a warranty claim" callback={() => setWarrantyModal(true)} disabled={job.jobStatus !== "open"} />
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center w-full gap-4 px-20 max-w-content">
                  <div className="flex flex-col items-start justify-start w-full gap-1">
                    <p className="text-sm font-semibold text-gray-800/80">Warranty Provider</p>
                    <Select
                      placeholder="Select a warranty provider"
                      options={customers}
                      onChange={(v) => updateProvider(v)}
                      defaultValue={job.warrantyInformation.warrantyProviderId}
                      className="w-full font-sans"
                      controls={false}
                      filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      showSearch
                      disabled={job.jobStatus !== "open"}
                    />
                  </div>
                  <div className="flex flex-col items-start justify-start w-full gap-1">
                    <p className="text-sm font-semibold text-gray-800/80">Warranty Claim No.</p>
                    <Input
                      placeholder="Claim No."
                      defaultValue={job.warrantyInformation.claimNo}
                      className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 hover:border-gray-400 focus:ring-0"
                      onChange={(e) =>
                        setWarrantyData({
                          ...warrantyData,
                          claimNo: e.target.value,
                        })
                      }
                      disabled={job.jobStatus !== "open"}
                    />
                  </div>
                  <div className="flex items-center justify-end w-full gap-1 pt-4 mt-2 border-t border-gray-300">
                    <PrimaryButton label="Save Changes" callback={() => updateWarranty()} disabled={job.jobStatus !== "open"} />
                  </div>
                </div>
              )}
            </div>
          </div>
          <Modal
            open={warrantyModal}
            onCancel={() => closeWarrantyModal()}
            title="Submit warranty information"
            onOk={() => openWarranty()}
            centered
            destroyOnClose
          >
            <div className="flex flex-col items-start justify-start w-full gap-3">
              <div className="flex flex-col items-start justify-start w-full gap-1">
                <p className="text-sm font-semibold text-gray-800/80">Warranty Provider</p>
                <Select
                  placeholder="Select a warranty provider"
                  options={customers}
                  onChange={(v) => updateProvider(v)}
                  defaultValue={warrantyData.warrantyProviderId ? warrantyData.warrantyProviderId : null}
                  className="w-full font-sans"
                  controls={false}
                  filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  showSearch
                />
              </div>
              <div className="flex flex-col items-start justify-start w-full gap-1">
                <p className="text-sm font-semibold text-gray-800/80">Warranty Claim No.</p>
                <Input
                  placeholder="Claim No."
                  defaultValue={warrantyData.claimNo}
                  className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 hover:border-gray-400 focus:ring-0"
                  onChange={(e) =>
                    setWarrantyData({
                      ...warrantyData,
                      claimNo: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default JobWarranty;
