import { useEffect, useState, Fragment, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Dialog, Transition, Switch } from "@headlessui/react";
import { GetOneJob } from "../../actions/jobs";
import toast from "react-hot-toast";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { formatCurrency } from "../../components/tools";
import * as _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import { Helmet } from "react-helmet-async";

const JobPurchaseOrders = ({ authState, authDispatch }) => {
  let [loading, setLoading] = useState(true);
  let [dummyLoading, setDummyLoading] = useState(false);
  let [job, setJob] = useState({});
  // eslint-disable-next-line
  let [purchaseOrders, setPurchaseOrders] = useState([]);

  const { jobId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetOneJob(jobId)
        .then((res) => {
          setJob(res.data.job);
          setPurchaseOrders(res.data.purchaseOrders);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
          setTimeout(() => {
            navigate("/jobs");
          }, 3000);
        });
    }
    return () => {
      inView = false;
    };
    // eslint-disable-next-line
  }, []);

  const tabs = [
    { name: "Info", href: "#", current: false },
    { name: "Parts", href: "parts", current: false },
    { name: "Labor", href: "labor", current: false },
    { name: "Parking", href: "parking", current: false },
    { name: "Loadbank", href: "loadbank", current: false },
    { name: "Freight", href: "freight", current: false },
    { name: "Misc", href: "misc", current: false },
    { name: "POs", href: "purchaseOrders", current: true },
    {
      name: "ITs",
      href: "inventoryTransmittals",
      current: false,
    },
    { name: "Equip.", href: "equipment", current: false },
    { name: "Tests", href: "tests", current: false },
    { name: "Warranty", href: "warranty", current: false },
    { name: "Payments", href: "payments", current: false },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const renderPOLine = (line) => {
    let poStatus = {
      draft: "Draft",
      approved: "Approved",
      denied: "Denied",
      submitted: "Submitted",
      invoiced: "Invoiced",
      received: "Received",
      void: "VOID",
      returned: "Returned",
      backOrdered: "Back Ordered",
    };

    let poTotal = 0;
    for (let i = 0; i < line.items.length; i++) {
      poTotal += line.items[i].price * line.items[i].quantity;
    }
    poTotal += line.tax;
    poTotal += line.shipping;
    return (
      <div className="grid items-center w-full grid-cols-3 gap-4 py-3 border-b border-gray-300 md:grid-cols-7" key={uuidv4()}>
        <p className="text-sm font-semibold text-gray-500 uppercase">{poStatus[line.poStatus]}</p>
        <p className="md:truncate">{line.poNumber}</p>
        <p className="hidden text-center capitalize md:inline-flex">{dayjs(line.poDate).format("MM/DD/YYYY")}</p>
        <p className="hidden col-span-2 capitalize md:inline-flex">
          {line.vendorCode}
          {" | "}
          {line.vendorName}
        </p>
        <p className="text-right">{formatCurrency(poTotal)}</p>
        <div className="flex-row items-center justify-center hidden gap-2 md:flex">
          {authState.user.functionCategory !== "technician" && (
            <SecondaryButton label="Open" callback={() => window.open(`/po/${line.poId}`, "_blank").focus()} />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
        </div>
      ) : (
        <>
          <Helmet>
            <title>Purchase Orders - {job.jobNo} | HTPS ERP</title>
          </Helmet>
          <div className="flex flex-row items-center justify-start w-full mb-3">
            <div className="w-full sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                id="tabs"
                name="tabs"
                className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:border-blue-700 focus:outline-none focus:ring-blue-700 sm:text-sm"
                defaultValue={tabs.find((tab) => tab.current).href}
                onChange={(v) => navigate(`/jobs/${jobId}/${v.target.value}`)}
              >
                {tabs.map((tab) => (
                  <option value={tab.href}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden w-full sm:block">
              <div className="border-b border-gray-200">
                <nav className="flex -mb-px space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <p
                      value={tab.name}
                      key={uuidv4()}
                      onClick={() => navigate(`/jobs/${jobId}/${tab.href}`)}
                      className={classNames(
                        tab.current ? "border-blue-700 text-blue-700" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer",
                      )}
                      aria-current={tab.current ? "page" : undefined}
                    >
                      {tab.name}
                    </p>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-start w-full px-1 mt-1">
            <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
              <p className="text-xl font-bold uppercase">Job Purchase Orders</p>
              {job.jobStatus === "open" && authState.user.functionCategory !== "technician" && (
                <SecondaryButton label="New Purchase Order" callback={() => navigate(`/po/new?jobId=${jobId}&locationId=${job.locationId}`)} />
              )}
            </div>
            <div className="grid w-full grid-cols-3 gap-4 py-3 border-b border-gray-300 md:grid-cols-7">
              <p className="text-xs font-semibold uppercase">Status</p>
              <p className="text-xs font-semibold uppercase">PO Number</p>
              <p className="hidden text-xs font-semibold text-center uppercase md:inline-flex">Order Date</p>
              <p className="hidden col-span-2 text-xs font-semibold uppercase md:inline-flex">Vendor</p>
              <p className="text-xs font-semibold text-right uppercase">Total</p>
              <div className="flex-row items-center justify-center hidden md:flex"></div>
            </div>
            {purchaseOrders.map((line, index) => renderPOLine(line, index))}
          </div>
        </>
      )}
    </div>
  );
};

export default JobPurchaseOrders;
