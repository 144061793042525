import axiosInstance from "./axios";
import { validate } from "uuid";
import qs from "qs";

export const GetAllCustomers = () => {
  return axiosInstance.get(`/customers`);
};

export const GetFullCustomerList = () => {
  return axiosInstance.get("/customers/list");
};

export const GetWarrantyProviders = () => {
  return axiosInstance.get("/customers/warrantyProviders");
};

export const AddCustomer = (data) => {
  return axiosInstance.post("/customers", data);
};

export const UpdateCustomer = (data) => {
  return axiosInstance.put("/customers", data);
};

export const GetOneCustomer = (id) => {
  return axiosInstance.get(`/customers/${id}`);
};

export const GetCustomerVersion = (id) => {
  return axiosInstance.get(`/customers/${id}/version`);
};

export const UpdateCustomerDefaults = (data, id) => {
  return axiosInstance.put(`/customers/${id}/defaults`, data);
};

export const UpdateCustomerContact = (data, id) => {
  return axiosInstance.put(`/customers/${id}/contact`, data);
};

export const UpdateCustomerAR = (data, id) => {
  return axiosInstance.put(`/customers/${id}/ar`, data);
};

export const AddNoteToCustomer = (data, id) => {
  return axiosInstance.put(`/customers/${id}/note`, data);
};

export const GetCustomerQuotes = (id) => {
  return axiosInstance.get(`/customers/${id}/quotes`);
};

export const GetCustomerJobs = (id) => {
  return axiosInstance.get(`/customers/${id}/jobs`);
};

export const GetCustomerInvoices = (id) => {
  return axiosInstance.get(`/customers/${id}/invoices`);
};

export const AddEquipmentToCustomer = (data, id) => {
  return axiosInstance.post(`/customers/${id}/equipment`, data);
};

export const AddAltContact = (data, id) => {
  return axiosInstance.post(`/customers/${id}/contact/altContact`, data);
};

export const AddArAltContact = (data, id) => {
  return axiosInstance.post(`/customers/${id}/ar/altContact`, data);
};

export const UpdateAltContact = (data, id) => {
  return axiosInstance.put(`/customers/${id}/contact/altContact/${data.contactId}`, data);
};

export const UpdateArAltContact = (data, id) => {
  return axiosInstance.put(`/customers/${id}/ar/altContact/${data.contactId}`, data);
};

export const DeleteAltContact = (data, id) => {
  return axiosInstance.delete(`/customers/${id}/contact/altContact/${data.contactId}`);
};

export const DeleteArAltContact = (data, id) => {
  return axiosInstance.delete(`/customers/${id}/ar/altContact/${data.contactId}`);
};

export const QueryCustomers = (query) => {
  let search = `/customers/query?search=${query}`;
  search = encodeURI(search);
  return axiosInstance.get(search);
};

export const ChangeCustomerCode = (data, id) => {
  return axiosInstance.put(`/customers/${id}/customerCode`, data);
};

export const ChangeCustomerCompany = (data, id) => {
  return axiosInstance.put(`/customers/${id}/company`, data);
};

export const QueryByEquipment = (data) => {
  let queryParams = {};
  if (data.customerId && validate(data.customerId)) {
    queryParams.customerId = data.customerId;
  }
  if (data.search && data.search.length > 2) {
    queryParams.search = data.search;
  }
  let queryString = qs.stringify(queryParams);
  return axiosInstance.get(`/customers/queryByEquipment?${queryString}`);
};

export const AdvancedQueryCustomers = (data) => {
  let queryParams = {};
  if (data.search) {
    queryParams.search = data.search;
  }
  if (data.customerId && validate(data.customerId)) {
    queryParams.customerId = data.customerId;
  }
  if (data.page) {
    queryParams.page = data.page;
  }
  if (data.limit) {
    queryParams.limit = data.limit;
  }
  let queryString = qs.stringify(queryParams);
  return axiosInstance.get(`/customers/advancedQuery?${queryString}`);
};

export const UpdateCustomerNote = (data, customerId, noteId) => {
  return axiosInstance.put(`/customers/${customerId}/note/${noteId}`, data);
};

export const DeleteCustomerNote = (customerId, noteId) => {
  return axiosInstance.delete(`/customers/${customerId}/note/${noteId}`);
};
