import { useEffect, useState, Fragment, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Dialog, Transition, Switch } from "@headlessui/react";
import { GetOneJob } from "../../actions/jobs";
import toast from "react-hot-toast";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { formatCurrency } from "../../components/tools";
import * as _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import { UpdateTransmittalStatus } from "../../actions/ims";
import { Helmet } from "react-helmet-async";

const locations = [
  {
    locationId: "a6fe18dd-b809-4a78-85ab-e767e2b8ebcf",
    locationName: "Augusta, GA",
  },
  {
    locationId: "1f1bf36d-b744-4f69-bc9c-ca2c32a5f66f",
    locationName: "North Augusta, SC",
  },
];

const JobInventoryTransmittals = ({ authState, authDispatch }) => {
  let [loading, setLoading] = useState(true);
  let [dummyLoading, setDummyLoading] = useState(false);
  let [job, setJob] = useState({});
  // eslint-disable-next-line
  let [employees, setEmployees] = useState([]);
  let [inventoryTransmittals, setInventoryTransmittals] = useState([]);
  let [updateStatus, setUpdateStatus] = useState(false);
  let [transmittal, setTransmittal] = useState({
    transmittalId: "",
    from: "",
    to: "",
    partId: "",
    partNo: "",
    description: "",
    quantity: "",
    price: "",
    cost: "",
    chargeOut: "",
    initiatedFor: "",
    initiatedBy: "",
    initiatedAt: "",
    status: "",
  });

  const { jobId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetOneJob(jobId)
        .then((res) => {
          setJob(res.data.job);
          setInventoryTransmittals(res.data.inventoryTransmittals);
          setEmployees(res.data.employees);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
          setTimeout(() => {
            navigate("/jobs");
          }, 3000);
        });
    }
    return () => {
      inView = false;
    };
    // eslint-disable-next-line
  }, []);

  const tabs = [
    { name: "Info", href: "#", current: false },
    { name: "Parts", href: "parts", current: false },
    { name: "Labor", href: "labor", current: false },
    { name: "Parking", href: "parking", current: false },
    { name: "Loadbank", href: "loadbank", current: false },
    { name: "Freight", href: "freight", current: false },
    { name: "Misc", href: "misc", current: false },
    { name: "POs", href: "purchaseOrders", current: false },
    {
      name: "ITs",
      href: "inventoryTransmittals",
      current: true,
    },
    { name: "Equip.", href: "equipment", current: false },
    { name: "Tests", href: "tests", current: false },
    { name: "Warranty", href: "warranty", current: false },
    { name: "Payments", href: "payments", current: false },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const renderITLine = (line) => {
    let itStatus = {
      draft: "Draft",
      inTransit: "In Transit",
      received: "Received",
      void: "VOID",
      returned: "Returned",
    };
    return (
      <div className="grid items-center w-full grid-cols-4 gap-4 py-3 border-b border-gray-300 md:grid-cols-7" key={uuidv4()}>
        <p className="text-sm font-semibold text-gray-500 uppercase">{itStatus[line.status]}</p>
        <p className="hidden capitalize md:inline-flex">{dayjs(line.initiatedAt).format("MM/DD/YYYY")}</p>
        <p className="md:truncate">{line.partNo}</p>
        <p className="hidden col-span-2 capitalize truncate md:inline-flex">
          {line.description.slice(0, 50)}
          {line.description.length > 50 && "..."}
        </p>
        <p className="md:text-right">{line.quantity}</p>
        <div className="flex flex-row items-center justify-center gap-2">
          {job.jobStatus === "open" && <SecondaryButton label="Update Status" callback={() => openUpdateStatus(line)} />}
        </div>
      </div>
    );
  };

  const renderUpdateStatus = () => {
    return (
      <Transition.Root show={updateStatus} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeUpdateStatus}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                  <div className="flex flex-col items-start justify-start w-full gap-2 mt-3">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Update Transmittal Status
                    </Dialog.Title>
                    <div className="w-full mt-2 border-gray-100 border-y">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-gray-900">Transfer From:</dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {locations.find((l) => l.locationId === transmittal.from)?.locationName}
                          </dd>
                        </div>
                        <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-gray-900">Transfer To:</dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {locations.find((l) => l.locationId === transmittal.to)?.locationName}
                          </dd>
                        </div>
                        <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-gray-900">Part No:</dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{transmittal.partNo}</dd>
                        </div>
                        <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-gray-900">Part Description:</dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {transmittal.description.slice(0, 50)}
                            {transmittal.description.length > 50 && "..."}
                          </dd>
                        </div>
                        <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-gray-900">Quantity:</dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{transmittal.quantity}</dd>
                        </div>
                        <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-gray-900">Price:</dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{formatCurrency(transmittal.price)}</dd>
                        </div>
                        <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-gray-900">Cost:</dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{formatCurrency(transmittal.cost)}</dd>
                        </div>
                        <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-gray-900">Charge Out:</dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{formatCurrency(transmittal.chargeOut)}</dd>
                        </div>
                        <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-gray-900">Initiated By:</dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {employees.find((e) => e.userId === transmittal.initiatedBy)?.firstName}{" "}
                            {employees.find((e) => e.userId === transmittal.initiatedBy)?.lastName}
                          </dd>
                        </div>
                      </dl>
                    </div>
                    <div className="flex flex-row items-center justify-between w-full gap-10 mt-2">
                      <label htmlFor="status" className="block text-sm font-medium leading-6 text-gray-900">
                        Status
                      </label>
                      <select
                        id="status"
                        name="status"
                        className="w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                        defaultValue={transmittal.status}
                        onChange={(e) => changeStatus(e.target.value)}
                      >
                        <option hidden disabled value="">
                          Select One
                        </option>
                        <option value="draft">Draft</option>
                        <option value="inTransit">In-Transit</option>
                        <option value="received">Received</option>
                        <option value="returned">Returned</option>
                        <option value="void">VOID</option>
                      </select>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-5 sm:mt-6">
                    <SecondaryButton label="Cancel" callback={() => closeUpdateStatus()} />
                    <PrimaryButton label="Update Transmittal" callback={() => submitStatusChange()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const changeStatus = (val) => {
    let tmp = transmittal;
    tmp.status = val;
    setTransmittal(tmp);
  };

  const submitStatusChange = () => {
    console.log(transmittal);
    let existing = inventoryTransmittals.find((t) => t.transmittalId === transmittal.transmittalId);
    if (existing.status === transmittal.status) {
      toast.error("No changes detected");
    } else {
      setLoading(true);
      UpdateTransmittalStatus(transmittal.transmittalId, {
        status: transmittal.status,
      })
        .then((res) => {
          toast.success("Transmittal status updated successfully!");
          closeUpdateStatus();
          GetOneJob(jobId)
            .then((res) => {
              setJob(res.data.job);
              setInventoryTransmittals(res.data.inventoryTransmittals);
              setEmployees(res.data.employees);
              setTimeout(() => setLoading(false), 700);
            })
            .catch((err) => {
              toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
              setTimeout(() => {
                navigate("/jobs");
              }, 3000);
            });
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error updating transmittal status");
          setLoading(false);
        });
    }
  };

  const closeUpdateStatus = () => {
    setUpdateStatus(false);
    setTransmittal({
      transmittalId: "",
      from: "",
      to: "",
      partId: "",
      partNo: "",
      description: "",
      quantity: "",
      price: "",
      cost: "",
      chargeOut: "",
      initiatedFor: "",
      initiatedBy: "",
      initiatedAt: "",
      status: "",
    });
  };

  const openUpdateStatus = (data) => {
    setTransmittal({
      transmittalId: data.transmittalId,
      from: data.from,
      to: data.to,
      partId: data.partId,
      partNo: data.partNo,
      description: data.description,
      quantity: data.quantity,
      price: data.price,
      cost: data.cost,
      chargeOut: data.chargeOut,
      initiatedFor: data.initiatedFor,
      initiatedBy: data.initiatedBy,
      initiatedAt: data.initiatedAt,
      status: data.status,
    });
    setUpdateStatus(true);
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
        </div>
      ) : (
        <>
          <Helmet>
            <title>Inventory Transmittals - {job.jobNo} | HTPS ERP</title>
          </Helmet>
          <div className="flex flex-row items-center justify-start w-full mb-3">
            <div className="w-full sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                id="tabs"
                name="tabs"
                className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:border-blue-700 focus:outline-none focus:ring-blue-700 sm:text-sm"
                defaultValue={tabs.find((tab) => tab.current).href}
                onChange={(v) => navigate(`/jobs/${jobId}/${v.target.value}`)}
              >
                {tabs.map((tab) => (
                  <option value={tab.href}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden w-full sm:block">
              <div className="border-b border-gray-200">
                <nav className="flex -mb-px space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <p
                      value={tab.name}
                      key={uuidv4()}
                      onClick={() => navigate(`/jobs/${jobId}/${tab.href}`)}
                      className={classNames(
                        tab.current ? "border-blue-700 text-blue-700" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer",
                      )}
                      aria-current={tab.current ? "page" : undefined}
                    >
                      {tab.name}
                    </p>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-start w-full px-1 mt-1">
            <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
              <p className="text-xl font-bold uppercase">Job Inventory Transmittals</p>
            </div>
            <div className="grid w-full grid-cols-4 gap-4 py-3 border-b border-gray-300 md:grid-cols-7">
              <p className="text-xs font-semibold uppercase">Status</p>
              <p className="hidden text-xs font-semibold uppercase md:inline-flex">Initiated At</p>
              <p className="text-xs font-semibold uppercase">Part No</p>
              <p className="hidden col-span-2 text-xs font-semibold uppercase md:inline-flex">Description</p>
              <p className="text-xs font-semibold text-right uppercase">Quantity</p>
              <div className="flex flex-row items-center justify-center"></div>
            </div>
            {inventoryTransmittals.map((line, index) => renderITLine(line, index))}
          </div>
          {renderUpdateStatus()}
        </>
      )}
    </div>
  );
};

export default JobInventoryTransmittals;
