import { Dropdown, Input, Modal } from "antd";
import dayjs from "dayjs";
import { Menu } from "lucide-react";
import { DeleteCustomerNote, UpdateCustomerNote } from "../../actions/customers";
import toast from "react-hot-toast";
import { useState } from "react";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";

const CustomerNote = ({ customerId, employees, note, reload }) => {
  const [editOpen, setEditOpen] = useState(false);
  const [noteEdit, setNoteEdit] = useState("");

  const items = [
    {
      key: "edit",
      label: "Edit",
    },
    {
      key: "delete",
      label: "Delete",
      danger: true,
    },
  ];

  const selectDropdown = ({ key }) => {
    if (!key) return;
    if (key === "edit") {
      setEditOpen(true);
    }
    if (key === "delete") {
      DeleteCustomerNote(customerId, note.noteId)
        .then((res) => {
          toast.success("Note Deleted Successfully");
          reload();
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Something went wrong, please try again");
        });
    }
  };

  const closeEdit = () => {
    setEditOpen(false);
    setNoteEdit("");
  };

  const submitNoteEdit = () => {
    if (!noteEdit || noteEdit === "") {
      toast.error("Please enter a note to update");
      return;
    }
    UpdateCustomerNote({ note: noteEdit }, customerId, note.noteId)
      .then((res) => {
        toast.success("Note Updated Successfully");
        closeEdit();
        reload();
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Something went wrong, please try again");
      });
  };

  return (
    <div className="flex flex-col items-start justify-center w-full px-3 py-2 bg-white rounded-lg">
      <h3 className="w-full text-sm font-base text-slate-800">{note.note}</h3>
      <div className="flex flex-row items-center justify-between w-full mt-1">
        <p className="text-xs text-slate-400/75 font-medium pt-0.5">{employees.find((emp) => emp.employeeId === note.createdBy).name}</p>
        <div className="flex items-center justify-end gap-1 pt-0.5">
          <p className="text-xs font-medium text-slate-400/75">{dayjs(note.createdAt).format("MM/DD/YYYY")}</p>
          <Dropdown menu={{ items: items, onClick: selectDropdown }}>
            <Menu size={14} className="-pt-0.5 cursor-pointer text-slate-600" />
          </Dropdown>
        </div>
      </div>
      <Modal open={editOpen} onClose={closeEdit} title="Edit Note" centered width={620} footer={[]}>
        <Input.TextArea
          placeholder="Enter your note here..."
          onChange={(e) => {
            setNoteEdit(e.target.value);
          }}
          rows={4}
          defaultValue={note.note}
          autoSize={false}
          className="w-full !resize-none font-sans py-2 px-4 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
        />
        <div className="flex items-center justify-end w-full gap-2 mt-4">
          <SecondaryButton label={"Cancel"} callback={() => closeEdit()} />
          <PrimaryButton label={"Update Note"} callback={() => submitNoteEdit()} />
        </div>
      </Modal>
    </div>
  );
};

export default CustomerNote;
