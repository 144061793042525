import axiosInstance from "./axios";

export const GetOpenAPInvoices = () => {
  return axiosInstance.get("/reports/openAPInvoices");
};

export const GetEquipmentLogByCustomer = (customerId) => {
  return axiosInstance.get(`/reports/equipmentLog/${customerId}`);
};

export const GetInventoryCount = () => {
  return axiosInstance.get("/reports/inventoryCount");
};

export const GetOpenJobs = () => {
  return axiosInstance.get("/reports/openJobs");
};

export const GetMonthlyServiceReport = () => {
  return axiosInstance.get("/reports/monthlyServiceReport");
};

export const GetDailyReport = (data) => {
  return axiosInstance.post("/reports/dailyTimeReport", data);
};

export const GetWeeklyReport = (data) => {
  return axiosInstance.post("/reports/weeklyTimeReport", data);
};

export const GetPayPeriods = () => {
  return axiosInstance.get("/reports/payPeriods");
};

export const GetTimesheet = (data) => {
  return axiosInstance.post("/reports/timesheet", data);
};

export const GetARAgingReport = () => {
  return axiosInstance.get("/reports/ARAgingReport");
};

export const GetCountData = () => {
  return axiosInstance.get("/reports/countData");
};

export const GetIncompleteParts = () => {
  return axiosInstance.get("/reports/incompleteParts");
};

export const GetCollectedSalesTax = (data) => {
  return axiosInstance.post("/reports/collectedSalesTax", data);
};
