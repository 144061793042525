import { useEffect, useState, Fragment, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Dialog, Transition, Switch } from "@headlessui/react";
import { GetOneJob, AddParkingToAJob, UpdateJobParking, RemoveJobParking } from "../../actions/jobs";
import toast from "react-hot-toast";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { formatCurrency } from "../../components/tools";
import * as _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { Cancel, EditPencil, Trash } from "iconoir-react";
import { DatePicker, TimePicker } from "antd";

import dayjs from "dayjs";
import { Helmet } from "react-helmet-async";

const JobParking = ({ authState, authDispatch }) => {
  let [loading, setLoading] = useState(true);
  let [dummyLoading, setDummyLoading] = useState(false);
  let [job, setJob] = useState({});
  // eslint-disable-next-line
  let [location, setLocation] = useState({});
  let [addParkingModal, setAddParkingModal] = useState(false);
  let [parkingData, setParkingData] = useState({
    parkingType: "",
    increment: "",
    startDate: "",
    endDate: "",
    unitCharge: 0,
    quantity: 0,
    totalCharge: 0,
    parkingId: "",
    units: 1,
  });
  let [editParkingModal, setEditParkingModal] = useState(false);

  const { jobId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetOneJob(jobId)
        .then((res) => {
          setJob(res.data.job);
          setLocation(res.data.location);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
          setTimeout(() => {
            navigate("/jobs");
          }, 3000);
        });
    }
    return () => {
      inView = false;
    };
    // eslint-disable-next-line
  }, []);

  const tabs = [
    { name: "Info", href: "#", current: false },
    { name: "Parts", href: "parts", current: false },
    { name: "Labor", href: "labor", current: false },
    { name: "Parking", href: "parking", current: true },
    { name: "Loadbank", href: "loadbank", current: false },
    { name: "Freight", href: "freight", current: false },
    { name: "Misc", href: "misc", current: false },
    { name: "POs", href: "purchaseOrders", current: false },
    {
      name: "ITs",
      href: "inventoryTransmittals",
      current: false,
    },
    { name: "Equip.", href: "equipment", current: false },
    { name: "Tests", href: "tests", current: false },
    { name: "Warranty", href: "warranty", current: false },
    { name: "Payments", href: "payments", current: false },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const renderParkingLine = (line, index) => {
    return (
      <div className="grid items-center w-full grid-cols-4 gap-4 py-3 border-b border-gray-300 md:grid-cols-6" key={uuidv4()}>
        <p className="capitalize">
          {line.increment} {line.parkingType} x {line.quantity || 1}
        </p>
        <p className="hidden md:inline-flex">
          {dayjs(line.startDate).add(10, "hours").format("MM/DD/YYYY")} - {dayjs(line.endDate).add(10, "hours").format("MM/DD/YYYY")}
        </p>
        <p className="hidden md:inline-flex">{formatCurrency(line.unitCharge)}</p>
        <p>{line.units}</p>
        <p>{formatCurrency(line.totalCharge)}</p>
        {job.jobStatus === "open" && authState.user.functionCategory !== "technician" && (
          <div className="flex flex-row items-center justify-center gap-2">
            <SecondaryButton label="Remove" callback={() => submitParkingRemove(line.parkingId)} />
            <SecondaryButton label="Edit" callback={() => openParkingEdit(line.parkingId)} />
          </div>
        )}
      </div>
    );
  };

  const renderAddParkingModal = () => {
    return (
      <Transition.Root show={addParkingModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeParkingModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:px-6">
                  <div>
                    <div>
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Add Parking/Storage Fee
                      </Dialog.Title>
                      <div className="flex flex-col items-center justify-start gap-4 mt-2">
                        <div key="parkingType" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="parkingType" className="pb-1 text-xs text-gray-600 uppercase">
                            Fee Type
                          </label>
                          <select
                            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                            id="parkingType"
                            defaultValue=""
                            onChange={changeParkingData}
                          >
                            <option hidden disabled value="">
                              Select One
                            </option>
                            <option key="parking" value="parking">
                              Parking
                            </option>
                            <option key="storage" value="storage">
                              Storage
                            </option>
                          </select>
                        </div>
                        <div key="startDate" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="startDate" className="pb-1 text-xs text-gray-600 uppercase">
                            Start Date
                          </label>
                          <DatePicker format={"MM/DD/YYYY"} onChange={(date, dateString) => changeParkingStart(dateString)} style={{ width: "100%" }} />
                        </div>
                        <div key="endDate" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="endDate" className="pb-1 text-xs text-gray-600 uppercase">
                            End Date
                          </label>
                          <DatePicker format={"MM/DD/YYYY"} onChange={(date, dateString) => changeParkingEnd(dateString)} style={{ width: "100%" }} />
                        </div>
                        <div key="increment" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="increment" className="pb-1 text-xs text-gray-600 uppercase">
                            Charge increment
                          </label>
                          <select
                            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                            id="increment"
                            defaultValue=""
                            onChange={changeParkingData}
                          >
                            <option hidden disabled value="">
                              Select One
                            </option>
                            <option key="daily" value="daily">
                              Daily
                            </option>
                            <option key="weekly" value="weekly">
                              Weekly
                            </option>
                            <option key="monthly" value="monthly">
                              Monthly
                            </option>
                            <option key="annually" value="annually">
                              Annually
                            </option>
                          </select>
                        </div>
                        <div key="unitCharge" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="unitCharge" className="pb-1 text-xs text-gray-600 uppercase">
                            Unit Charge
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="unitCharge"
                            onChange={changeParkingData}
                          />
                        </div>
                        <div key="units" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="units" className="pb-1 text-xs text-gray-600 uppercase">
                            # of Units Stored
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="units"
                            onChange={changeParkingData}
                          />
                        </div>
                        <div className="flex flex-col items-end justify-center w-full gap-1 px-1 py-2 border-gray-300 border-y">
                          <p className="text-sm uppercase">
                            Unit Charge Quantity: <span className="font-semibold">{parkingData.quantity}</span>
                          </p>
                          <p className="text-sm uppercase">
                            Times units: <span className="font-semibold">{parkingData.units}</span>
                          </p>
                          <p className="text-sm uppercase">
                            Total: <span className="font-semibold">{formatCurrency(parkingData.totalCharge)}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-5">
                    <SecondaryButton label="Cancel" callback={() => closeParkingModal()} />
                    <PrimaryButton label="Add Parking/Storage Fee" callback={() => addNewParking()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const closeParkingModal = () => {
    setAddParkingModal(false);
    setParkingData({
      parkingType: "",
      increment: "",
      startDate: "",
      endDate: "",
      unitCharge: 0,
      quantity: 0,
      totalCharge: 0,
      parkingId: "",
      units: 1,
    });
  };

  const changeParkingData = (e) => {
    let { id, value } = e.target;
    setDummyLoading(true);
    let tmp = parkingData;
    tmp[id] = value;
    if (tmp.startDate !== "" && tmp.endDate !== "" && tmp.increment !== "") {
      let start = dayjs(tmp.startDate);
      let end = dayjs(tmp.endDate);
      let diff = end.diff(start, "days");
      if (tmp.increment === "daily") {
        tmp.quantity = Math.ceil(diff);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2)) * parseFloat(tmp.units);
        }
      } else if (tmp.increment === "weekly") {
        tmp.quantity = Math.ceil(diff / 7);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2)) * parseFloat(tmp.units);
        }
      } else if (tmp.increment === "monthly") {
        tmp.quantity = Math.ceil(diff / 30);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2)) * parseFloat(tmp.units);
        }
      } else if (tmp.increment === "annually") {
        tmp.quantity = Math.ceil(diff / 365);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2)) * parseFloat(tmp.units);
        }
      }
    }
    setParkingData(tmp);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const changeParkingStart = (date) => {
    let tmp = parkingData;
    setDummyLoading(true);
    tmp.startDate = date;
    if (tmp.startDate !== "" && tmp.endDate !== "" && tmp.increment !== "") {
      let start = dayjs(tmp.startDate).set("hour", 7).set("minute", 0);
      let end = dayjs(tmp.endDate).set("hour", 7).set("minute", 0);
      let diff = end.diff(start, "days");
      if (tmp.increment === "daily") {
        tmp.quantity = Math.ceil(diff);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2));
        }
      } else if (tmp.increment === "weekly") {
        tmp.quantity = Math.ceil(diff / 7);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2));
        }
      } else if (tmp.increment === "monthly") {
        tmp.quantity = Math.ceil(diff / 30);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2));
        }
      } else if (tmp.increment === "annually") {
        tmp.quantity = Math.ceil(diff / 365);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2));
        }
      }
    }
    setParkingData(tmp);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const changeParkingEnd = (date) => {
    let tmp = parkingData;
    setDummyLoading(true);
    tmp.endDate = date;
    if (tmp.startDate !== "" && tmp.endDate !== "" && tmp.increment !== "") {
      let start = dayjs(tmp.startDate).set("hour", 7).set("minute", 0);
      let end = dayjs(tmp.endDate).set("hour", 7).set("minute", 0);
      let diff = end.diff(start, "days");
      if (tmp.increment === "daily") {
        tmp.quantity = Math.ceil(diff);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2));
        }
      } else if (tmp.increment === "weekly") {
        tmp.quantity = Math.ceil(diff / 7);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2));
        }
      } else if (tmp.increment === "monthly") {
        tmp.quantity = Math.ceil(diff / 30);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2));
        }
      } else if (tmp.increment === "annually") {
        tmp.quantity = Math.ceil(diff / 365);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2));
        }
      }
    }
    setParkingData(tmp);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const addNewParking = () => {
    let failed = false;
    if (parkingData.parkingType === "") {
      toast.error("Please select a fee type");
      failed = true;
    } else if (parkingData.increment === "") {
      toast.error("Please select a charge increment");
      failed = true;
    } else if (parkingData.startDate === "") {
      toast.error("Please select a start date");
      failed = true;
    } else if (parkingData.endDate === "") {
      toast.error("Please select an end date");
      failed = true;
    } else if (parkingData.unitCharge === 0) {
      toast.error("Please enter a unit charge");
      failed = true;
    } else if (parkingData.quantity === 0 || parkingData.totalCharge === 0) {
      toast.error("There is not enough quantity for the charge");
      failed = true;
    }
    if (!failed) {
      setLoading(true);
      AddParkingToAJob(jobId, parkingData)
        .then((res) => {
          toast.success("Parking/Storage fee added successfully");
          closeParkingModal();
          GetOneJob(jobId)
            .then((res) => {
              setJob(res.data.job);
              setLocation(res.data.location);
              setTimeout(() => setLoading(false), 700);
            })
            .catch((err) => {
              toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
              setTimeout(() => {
                navigate("/jobs");
              }, 3000);
            });
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error adding parking to this job");
          setLoading(false);
        });
    }
  };

  const renderParkingEditModal = () => {
    return (
      <Transition.Root show={editParkingModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeParkingEdit}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:px-6">
                  <div>
                    <div>
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Edit Parking/Storage Fee
                      </Dialog.Title>
                      <div className="flex flex-col items-center justify-start gap-4 mt-2">
                        <div key="parkingType" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="parkingType" className="pb-1 text-xs text-gray-600 uppercase">
                            Fee Type
                          </label>
                          <select
                            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                            id="parkingType"
                            defaultValue={parkingData.parkingType}
                            onChange={changeParkingData}
                          >
                            <option hidden disabled value="">
                              Select One
                            </option>
                            <option key="parking" value="parking">
                              Parking
                            </option>
                            <option key="storage" value="storage">
                              Storage
                            </option>
                          </select>
                        </div>
                        <div key="startDate" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="startDate" className="pb-1 text-xs text-gray-600 uppercase">
                            Start Date
                          </label>
                          <DatePicker
                            onChange={(date, dateString) => changeParkingStart(dateString)}
                            defaultValue={dayjs(parkingData.startDate)}
                            style={{ width: "100%" }}
                            format={"MM/DD/YYYY"}
                          />
                        </div>
                        <div key="endDate" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="endDate" className="pb-1 text-xs text-gray-600 uppercase">
                            End Date
                          </label>
                          <DatePicker
                            onChange={(date, dateString) => changeParkingEnd(dateString)}
                            defaultValue={dayjs(parkingData.endDate)}
                            format={"MM/DD/YYYY"}
                            style={{ width: "100%" }}
                          />
                        </div>
                        <div key="increment" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="increment" className="pb-1 text-xs text-gray-600 uppercase">
                            Charge increment
                          </label>
                          <select
                            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                            id="increment"
                            defaultValue={parkingData.increment}
                            onChange={changeParkingData}
                          >
                            <option hidden disabled value="">
                              Select One
                            </option>
                            <option key="daily" value="daily">
                              Daily
                            </option>
                            <option key="weekly" value="weekly">
                              Weekly
                            </option>
                            <option key="monthly" value="monthly">
                              Monthly
                            </option>
                            <option key="annually" value="annually">
                              Annually
                            </option>
                          </select>
                        </div>
                        <div key="unitCharge" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="unitCharge" className="pb-1 text-xs text-gray-600 uppercase">
                            Unit Charge
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="unitCharge"
                            defaultValue={parkingData.unitCharge}
                            onChange={changeParkingData}
                          />
                        </div>
                        <div key="units" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="units" className="pb-1 text-xs text-gray-600 uppercase">
                            # of Units Stored
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="units"
                            defaultValue={parkingData.units}
                            onChange={changeParkingData}
                          />
                        </div>
                        <div className="flex flex-col items-end justify-center w-full gap-1 px-1 py-2 border-gray-300 border-y">
                          <p className="text-sm uppercase">
                            Unit Charge Quantity: <span className="font-semibold">{parkingData.quantity}</span>
                          </p>
                          <p className="text-sm uppercase">
                            Times units: <span className="font-semibold">{parkingData.units}</span>
                          </p>
                          <p className="text-sm uppercase">
                            Total: <span className="font-semibold">{formatCurrency(parkingData.totalCharge)}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-5">
                    <SecondaryButton label="Cancel" callback={() => closeParkingEdit()} />
                    <PrimaryButton label="Save" callback={() => submitParkingEdit()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const closeParkingEdit = () => {
    setEditParkingModal(false);
    setParkingData({
      parkingType: "",
      increment: "",
      startDate: "",
      endDate: "",
      unitCharge: 0,
      quantity: 0,
      totalCharge: 0,
      parkingId: "",
    });
  };

  const openParkingEdit = (id) => {
    let prk = _.find(job.parking, { parkingId: id });
    if (prk) {
      setParkingData(prk);
      setTimeout(() => setEditParkingModal(true), 700);
    } else {
      toast.error("Something went wrong, please try again");
    }
  };

  const submitParkingEdit = () => {
    setLoading(true);
    UpdateJobParking(jobId, parkingData.parkingId, parkingData)
      .then((res) => {
        toast.success("Parking/Storage fee updated successfully");
        closeParkingEdit();
        GetOneJob(jobId)
          .then((res) => {
            setJob(res.data.job);
            setLocation(res.data.location);
            setTimeout(() => setLoading(false), 700);
          })
          .catch((err) => {
            toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
            setTimeout(() => {
              navigate("/jobs");
            }, 3000);
          });
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error updating parking/storage entry");
        setLoading(false);
      });
  };

  const submitParkingRemove = (parkingId) => {
    setLoading(true);
    RemoveJobParking(jobId, parkingId)
      .then((res) => {
        toast.success("Parking/Storage fee removed successfully");
        GetOneJob(jobId)
          .then((res) => {
            setJob(res.data.job);
            setLocation(res.data.location);
            setTimeout(() => setLoading(false), 700);
          })
          .catch((err) => {
            toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
            setTimeout(() => {
              navigate("/jobs");
            }, 3000);
          });
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error removing parking/storage fee");
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
        </div>
      ) : (
        <>
          <Helmet>
            <title>Parking / Storage - {job.jobNo} | HTPS ERP</title>
          </Helmet>
          <div className="flex flex-row items-center justify-start w-full mb-3">
            <div className="w-full sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                id="tabs"
                name="tabs"
                className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:border-blue-700 focus:outline-none focus:ring-blue-700 sm:text-sm"
                defaultValue={tabs.find((tab) => tab.current).href}
                onChange={(v) => navigate(`/jobs/${jobId}/${v.target.value}`)}
              >
                {tabs.map((tab) => (
                  <option value={tab.href}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden w-full sm:block">
              <div className="border-b border-gray-200">
                <nav className="flex -mb-px space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <p
                      value={tab.name}
                      key={uuidv4()}
                      onClick={() => navigate(`/jobs/${jobId}/${tab.href}`)}
                      className={classNames(
                        tab.current ? "border-blue-700 text-blue-700" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer",
                      )}
                      aria-current={tab.current ? "page" : undefined}
                    >
                      {tab.name}
                    </p>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-start w-full px-1 mt-1">
            <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
              <p className="text-xl font-bold uppercase">Job Parking/Storage</p>
              {job.jobStatus === "open" && <SecondaryButton label="Add Parking/Storage" callback={() => setAddParkingModal(true)} />}
            </div>
            <div className="grid w-full grid-cols-4 gap-4 py-3 border-b border-gray-300 md:grid-cols-6">
              <p className="text-xs font-semibold uppercase">Type</p>
              <p className="hidden text-xs font-semibold uppercase md:inline-flex">Dates</p>
              <p className="hidden text-xs font-semibold uppercase md:inline-flex">Price</p>
              <p className="text-xs font-semibold uppercase">Quantity</p>
              <p className="text-xs font-semibold uppercase">Ext. Price</p>
              <div className="flex flex-row items-center justify-center"></div>
            </div>
            {job.parking.map((line, index) => renderParkingLine(line, index))}
          </div>
          {renderAddParkingModal()}
          {renderParkingEditModal()}
        </>
      )}
    </div>
  );
};

export default JobParking;
