import { useEffect, useState, Fragment, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Dialog, Transition, Combobox } from "@headlessui/react";
import toast from "react-hot-toast";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { formatCurrency } from "../../components/tools";
import * as _ from "lodash";
import { v4 as uuidv4, validate as validateUUID } from "uuid";
import {
  AddPONote,
  ApprovePO,
  DenyPO,
  GetOnePurchaseOrder,
  ReceivePO,
  RemovePOItem,
  SubmitPO,
  SubmitPOInvoice,
  UpdatePurchaseOrder,
  VOIDPurchaseOrder,
} from "../../actions/ims";
import { ErrorMessage } from "@hookform/error-message";
import { Controller, FormProvider, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { DatePicker, Input, InputNumber, Modal, Select } from "antd";
import validator from "validator";
import { Xmark, ArrowSeparateVertical, Check, OpenNewWindow } from "iconoir-react";
import { generatePoPreview } from "../../data/pdf";
import { Edit2, Trash2 } from "lucide-react";
import { Helmet } from "react-helmet-async";

const OpenPO = ({ authState, authDispatch }) => {
  let [loading, setLoading] = useState(true);
  let [dummyLoading, setDummyLoading] = useState(false);
  let [po, setPo] = useState({ items: [] });
  let [locations, setLocations] = useState([]);
  let [vendors, setVendors] = useState([]);
  let [parts, setParts] = useState([]);
  let [jobs, setJobs] = useState([]);
  let [accounts, setAccounts] = useState([]);
  let [serializedItems, setSerializedItems] = useState([]);
  let [nonSerializedItems, setNonSerializedItems] = useState([]);
  let [services, setServices] = useState([]);
  let [addSerialModal, setAddSerialModal] = useState(false);
  let [serialToAdd, setSerialToAdd] = useState({
    partId: "",
    partNo: "",
    quantity: 1,
    price: 0,
    costPrice: 0,
    costTotal: 0,
    total: 0,
    markup: 45,
  });
  let [selectedSerialPart, setSelectedSerialPart] = useState(null);
  let [serialQuery, setSerialQuery] = useState("");
  let [addNonSerialModal, setAddNonSerialModal] = useState(false);
  let [nonSerialToAdd, setNonSerialToAdd] = useState({
    partNo: "",
    quantity: 1,
    price: 0,
    costPrice: 0,
    costTotal: 0,
    description: "",
    markup: 45,
    total: 0,
  });
  let [comment, setComment] = useState("");
  let [modalType, setModalType] = useState("");
  let [modalOpen, setModalOpen] = useState(false);
  let [submitData, setSubmitData] = useState({
    expectedDeliveryDate: "",
    dateSent: "",
  });
  let [submitPOModal, setSubmitPOModal] = useState(false);
  let [receivePartsModal, setReceivePartsModal] = useState(false);
  let [receivedParts, setReceivedParts] = useState([]);
  let [invoiceData, setInvoiceData] = useState({
    invoiceNumber: "",
    invoiceDate: "",
    dueDate: "",
    items: [],
    subtotal: 0,
    shipping: 0,
    tax: 0,
    total: 0,
    account: null,
  });
  let [invoiceModal, setInvoiceModal] = useState(false);
  let [noteModal, setNoteModal] = useState(false);
  let [note, setNote] = useState("");
  let [customer, setCustomer] = useState(null);
  let [addServiceModal, setAddServiceModal] = useState(false);
  let [serviceToAdd, setServiceToAdd] = useState({
    partNo: "",
    quantity: 1,
    price: 0,
    costPrice: 0,
    costTotal: 0,
    description: "",
    markup: 45,
    total: 0,
    service: true,
  });
  let [count, setCount] = useState(0);
  let [editSerial, setEditSerial] = useState(false);
  let [editNonSerial, setEditNonSerial] = useState(false);
  let [editService, setEditService] = useState(false);

  const { poId } = useParams();
  const navigate = useNavigate();

  const formMethods = useForm();
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors, isDirty, dirtyFields },
    setValue,
    getValues,
    setError,
    control,
  } = formMethods;

  let editable = ["draft", "approved", "denied", "submitted", "invoiced", "received", "void", "returned", "backOrdered", ""];
  let editableExpAndDeliveryDate = ["draft", "approved", "denied", "submitted", "invoiced"];
  let editableShipping = ["draft", "approved", "denied", "submitted"];

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetOnePurchaseOrder(poId)
        .then((res) => {
          setPo(res.data.purchaseOrder);
          setLocations(res.data.locations);
          setVendors(res.data.vendors);
          setParts(res.data.parts);
          setJobs(res.data.jobs);
          setAccounts(res.data.accounts);
          let tmpItems = res.data.purchaseOrder.items;
          let tmpSerial = [];
          let tmpNonSerial = [];
          let tmpServices = [];
          for (let i = 0; i < tmpItems.length; i++) {
            let item = tmpItems[i];
            if (item.service) {
              tmpServices.push(item);
            } else if (item.partId && item.partId.length > 0) {
              tmpSerial.push(item);
            } else {
              tmpNonSerial.push(item);
            }
          }
          setSerializedItems(tmpSerial);
          setNonSerializedItems(tmpNonSerial);
          setServices(tmpServices);
          setCount(tmpSerial.length + tmpNonSerial.length + tmpServices.length);
          if (
            res.data.purchaseOrder.partsFor === "job" &&
            res.data.purchaseOrder.jobReference &&
            res.data.purchaseOrder.jobReference.length > 10 &&
            res.data.customer
          ) {
            setCustomer(res.data.customer);
          }
          setTimeout(() => setLoading(false), 700);
          let tmp = res.data.purchaseOrder;
          setValue("vendorId", tmp.vendorId, { shouldValidate: true });
          setValue("poAccount", tmp.poAccount, { shouldValidate: true });
          setValue("shipping", tmp.shipping || 0, {
            shouldValidate: true,
          });
          if (tmp.expectedDeliveryDate) {
            setValue("expectedDeliveryDate", tmp.expectedDeliveryDate, {
              shouldValidate: true,
            });
          }
          setValue("talkedTo", tmp.talkedTo || "", { shouldValidate: true });
          setValue("poStatus", tmp.poStatus, { shouldValidate: true });
          setValue("jobReference", tmp.jobReference, { shouldValidate: true });
          if (tmp.dateSent) {
            setValue("dateSent", tmp.dateSent, {
              shouldValidate: true,
            });
          }
          if (tmp.dateReceived) {
            setValue("dateReceived", tmp.dateReceived, {
              shouldValidate: true,
            });
          }
          setValue("locationId", tmp.locationId, { shouldValidate: true });
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
          setTimeout(() => {
            navigate("/po");
          }, 3000);
        });
    }
    return () => {
      inView = false;
    };
    // eslint-disable-next-line
  }, []);

  const reloadData = () => {
    GetOnePurchaseOrder(poId)
      .then((res) => {
        setPo(res.data.purchaseOrder);
        setLocations(res.data.locations);
        setVendors(res.data.vendors);
        setParts(res.data.parts);
        setJobs(res.data.jobs);
        setAccounts(res.data.accounts);
        let tmpItems = res.data.purchaseOrder.items;
        let tmpSerial = [];
        let tmpNonSerial = [];
        let tmpServices = [];
        for (let i = 0; i < tmpItems.length; i++) {
          let item = tmpItems[i];
          if (item.service) {
            tmpServices.push(item);
          } else if (item.partId && item.partId.length > 0) {
            tmpSerial.push(item);
          } else {
            tmpNonSerial.push(item);
          }
        }
        setSerializedItems(tmpSerial);
        setNonSerializedItems(tmpNonSerial);
        setServices(tmpServices);
        setCount(tmpSerial.length + tmpNonSerial.length + tmpServices.length);
        setTimeout(() => setLoading(false), 700);
        let tmp = res.data.purchaseOrder;
        setValue("vendorId", tmp.vendorId, { shouldValidate: true });
        setValue("poAccount", tmp.poAccount, { shouldValidate: true });
        setValue("shipping", tmp.shipping || 0, {
          shouldValidate: true,
        });
        if (tmp.expectedDeliveryDate) {
          setValue("expectedDeliveryDate", tmp.expectedDeliveryDate, {
            shouldValidate: true,
          });
        }
        setValue("talkedTo", tmp.talkedTo || "", { shouldValidate: true });
        setValue("poStatus", tmp.poStatus, { shouldValidate: true });
        setValue("jobReference", tmp.jobReference, { shouldValidate: true });
        if (tmp.dateSent) {
          setValue("dateSent", tmp.dateSent, {
            shouldValidate: true,
          });
        }
        if (tmp.dateReceived) {
          setValue("dateReceived", tmp.dateReceived, {
            shouldValidate: true,
          });
        }
        setValue("locationId", tmp.locationId, { shouldValidate: true });
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
        setTimeout(() => {
          navigate("/po");
        }, 3000);
      });
  };

  const onSubmit = (data) => {
    let tmp = data;
    tmp.items = [];
    for (let i = 0; i < serializedItems.length; i++) {
      let item = serializedItems[i];
      tmp.items.push(item);
    }
    for (let i = 0; i < nonSerializedItems.length; i++) {
      let item = nonSerializedItems[i];
      tmp.items.push(item);
    }
  };

  const sentStatuses = ["submitted", "invoiced", "received", "returned", "void", "backOrdered"];

  const changeSerialPartQuantity = (e) => {
    setDummyLoading(true);
    let tmp = serializedItems;
    let { id, value } = e.target;
    if (value !== "0" || value !== "") {
      let part = parts.find((pt) => pt.partId === id);
      let existing = _.find(tmp, (p) => p.partId === id);
      if (existing && existing !== undefined) {
        existing.quantity = value;
        existing.total = parseFloat((existing.price * value).toFixed(2));
        let index = _.findIndex(tmp, (p) => p.itemId === existing.itemId);
        tmp[index] = existing;
        setSerializedItems(tmp);
      } else {
        toast.error("Error updating part quantity");
      }
    }
    setTimeout(() => setDummyLoading(false), 300);
  };

  const changeSerialPartPrice = (e) => {
    setDummyLoading(true);
    let tmp = serializedItems;
    let { id, value } = e.target;
    if (value !== "0" || value !== "") {
      let part = parts.find((pt) => pt.partId === id);
      let existing = _.find(tmp, (p) => p.partId === id);
      if (existing && existing !== undefined) {
        existing.price = value;
        existing.total = parseFloat((existing.quantity * value).toFixed(2));
        let index = _.findIndex(tmp, (p) => p.itemId === existing.itemId);
        tmp[index] = existing;
        setSerializedItems(tmp);
      } else {
        toast.error("Error updating part price");
      }
    }
    setTimeout(() => setDummyLoading(false), 300);
  };

  const changeNonSerialPartQuantity = (e) => {
    setDummyLoading(true);
    let tmp = nonSerializedItems;
    let { id, value } = e.target;
    if (value !== "0" || value !== "") {
      let existing = _.find(tmp, (p) => p.itemId === id);
      if (existing && existing !== undefined) {
        existing.quantity = value;
        existing.total = parseFloat((existing.price * value).toFixed(2));
        let index = _.findIndex(tmp, (p) => p.itemId === existing.itemId);
        tmp[index] = existing;
        setNonSerializedItems(tmp);
      } else {
        toast.error("Error updating part quantity");
      }
    }
    setTimeout(() => setDummyLoading(false), 300);
  };

  const changeNonSerialPartPrice = (e) => {
    setDummyLoading(true);
    let tmp = nonSerializedItems;
    let { id, value } = e.target;
    if (value !== "0" || value !== "") {
      let existing = _.find(tmp, (p) => p.itemId === id);
      if (existing && existing !== undefined) {
        existing.price = value;
        existing.total = parseFloat((existing.quantity * value).toFixed(2));
        let index = _.findIndex(tmp, (p) => p.itemId === existing.itemId);
        tmp[index] = existing;
        setNonSerializedItems(tmp);
      } else {
        toast.error("Error updating part price");
      }
    }
    setTimeout(() => setDummyLoading(false), 300);
  };

  const removeSerialPart = (itemId) => {
    Modal.confirm({
      title: "Remove Serialized Part",
      icon: <Trash2 className="w-auto h-full mr-2 text-red-500" />,
      content: "Are you sure you want to remove this part from the purchase order? If this part was added to the job - it will be removed from it as well.",
      okText: "Remove",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        setDummyLoading(true);
        setLoading(true);
        let tmpNonSerial = serializedItems.filter((pt) => pt.itemId !== itemId);
        setSerializedItems(tmpNonSerial);
        RemovePOItem(poId, itemId).then((res) => {
          toast.success("Serialized part removed successfully");
          setTimeout(() => {
            setLoading(false);
            setDummyLoading(false);
          }, 700);
        });
      },
      onCancel() {},
      centered: true,
      width: 550,
    });
  };

  const removeNonSerialPart = (itemId) => {
    Modal.confirm({
      title: "Remove Non-Serial Part",
      icon: <Trash2 className="w-auto h-full mr-2 text-red-500" />,
      content:
        "Are you sure you want to remove this misc part from the purchase order? If this part was added to the job - it will be removed from it as well.",
      okText: "Remove",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        setDummyLoading(true);
        setLoading(true);
        let tmpNonSerial = nonSerializedItems.filter((pt) => pt.itemId !== itemId);
        setNonSerializedItems(tmpNonSerial);
        RemovePOItem(poId, itemId).then((res) => {
          toast.success("Non-Serial part removed successfully");
          setTimeout(() => {
            setLoading(false);
            setDummyLoading(false);
          }, 700);
        });
      },
      onCancel() {},
      centered: true,
      width: 550,
    });
  };

  const openAddSerialPart = () => {
    if (!editable.includes(po.poStatus)) {
      toast.error("This PO is not editable");
    } else if (po.vendorId && po.vendorId.length > 0) {
      setAddSerialModal(true);
    } else {
      toast.error("Please select a vendor first");
    }
  };

  const closeAddSerialPart = () => {
    setAddSerialModal(false);
    setSerialToAdd({
      partId: "",
      partNo: "",
      quantity: 1,
      price: 0,
      costPrice: 0,
      costTotal: 0,
      total: 0,
      markup: 45,
    });
    setSelectedSerialPart(null);
    setSerialQuery("");
  };

  const editSerialPartChange = (value, name) => {
    setDummyLoading(true);
    let tmp = serialToAdd;
    let oldVal = tmp[name];
    try {
      tmp[name] = parseFloat(value);
    } catch {
      tmp[name] = oldVal;
    }
    if (name === "costPrice") {
      tmp.price = value * (1 + tmp.markup / 100);
      tmp.total = tmp.price * tmp.quantity;
    } else if (name === "markup") {
      tmp.price = tmp.costPrice * (1 + value / 100);
      tmp.total = tmp.price * tmp.quantity;
    } else if (name === "price") {
      tmp.total = value * tmp.quantity;
      tmp.markup = ((tmp.price - tmp.costPrice) / tmp.costPrice) * 100;
    } else if (name === "quantity") {
      tmp.total = value * tmp.price;
    }
    setSerialToAdd(tmp);
    setTimeout(() => setDummyLoading(false), 300);
  };

  const pickSerialPart = (id) => {
    let part = parts.find((p) => p.partId === id);
    if (part) {
      setDummyLoading(true);
      setSelectedSerialPart(id);
      setSerialToAdd({
        partId: part.partId,
        partNo: part.partNo,
        quantity: 1,
        price: part.chargeOut,
        costPrice: part.cost,
        costTotal: part.markup,
        total: part.chargeOut,
        markup: part.markup,
      });
      setTimeout(() => setDummyLoading(false), 300);
    } else {
      toast.error("Error selecting part");
    }
  };

  const submitAddSerial = () => {
    let failed = false;
    if (!selectedSerialPart) {
      toast.error("Please select a part");
      failed = true;
    } else if (serialToAdd.quantity === 0) {
      toast.error("Please enter a part quantity");
      failed = true;
    }
    if (!failed) {
      setLoading(true);
      let tmp = serializedItems;
      let onePart = parts.find((p) => p.partId === selectedSerialPart);
      let price = serialToAdd.price === 0 ? onePart.chargeOut : serialToAdd.price;
      tmp.push({
        itemId: uuidv4(),
        partId: onePart.partId,
        partNo: onePart.partNo,
        description: onePart.description,
        quantity: serialToAdd.quantity,
        price: price,
        costPrice: serialToAdd.costPrice,
        costTotal: serialToAdd.costPrice * serialToAdd.quantity,
        total: serialToAdd.total,
        markup: serialToAdd.markup,
      });
      setSerializedItems(tmp);
      closeAddSerialPart();
      toast.success("Part added, please save changes for it to take effect");
      setTimeout(() => setLoading(false), 700);
    }
  };

  const renderAddSerial = () => {
    return (
      <Transition.Root show={addSerialModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeAddSerialPart}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl">
                  <div className="flex flex-col items-start justify-start w-full gap-2">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Add A Serialized Part to PO
                    </Dialog.Title>
                    <div className="w-full mt-2">
                      <label htmlFor="part" className="block text-sm font-medium leading-6 text-gray-900">
                        Part
                      </label>
                      <Select
                        options={parts.map((c) => ({
                          value: c.partId,
                          label: `${c.partNo}${c.description && c.description.length > 0 ? " | " + c.description : ""}`,
                        }))}
                        filterOption={filterOption}
                        className="w-full font-sans"
                        controls={false}
                        showSearch
                        placeholder="Search / Select a part"
                        onChange={pickSerialPart}
                      />
                    </div>
                    <div className="w-full">
                      <label htmlFor="costPrice" className="block text-sm font-medium leading-6 text-gray-900">
                        Cost
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <InputNumber
                          placeholder="0.00"
                          name="costPrice"
                          onChange={(v) => editSerialPartChange(v, "costPrice")}
                          value={serialToAdd.costPrice}
                          step={0.01}
                          min={0}
                          className="w-full font-sans py-0.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                          controls={false}
                          addonBefore="$"
                          addonAfter="USD"
                          disabled={selectedSerialPart ? false : true}
                        />
                      </div>
                    </div>
                    <div className="w-full">
                      <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                        Markup
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <InputNumber
                          placeholder="0.00"
                          name="markup"
                          onChange={(v) => editSerialPartChange(v, "markup")}
                          value={serialToAdd.markup}
                          step={0.01}
                          min={0}
                          className="w-full font-sans py-0.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                          controls={false}
                          disabled={selectedSerialPart ? false : true}
                          addonAfter="%"
                        />
                      </div>
                    </div>
                    <div className="w-full">
                      <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                        Ext Price
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <InputNumber
                          placeholder="0.00"
                          name="price"
                          onChange={(v) => editSerialPartChange(v, "price")}
                          value={serialToAdd.price}
                          step={0.01}
                          min={0}
                          className="w-full font-sans py-0.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                          controls={false}
                          addonBefore="$"
                          addonAfter="USD"
                          disabled={selectedSerialPart ? false : true}
                        />
                      </div>
                    </div>
                    <div className="w-full">
                      <label htmlFor="quantity" className="block text-sm font-medium leading-6 text-gray-900">
                        Quantity
                      </label>
                      <div className="mt-2">
                        <InputNumber
                          placeholder="0.00"
                          name="quantity"
                          onChange={(v) => editSerialPartChange(v, "quantity")}
                          value={serialToAdd.quantity}
                          step={0.01}
                          min={0}
                          className="w-full font-sans py-0.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                          controls={false}
                          disabled={selectedSerialPart ? false : true}
                        />
                      </div>
                    </div>
                    <div className="w-full">
                      <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                        Total
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <InputNumber
                          placeholder="0.00"
                          name="total"
                          onChange={(v) => editSerialPartChange(v, "total")}
                          value={serialToAdd.total}
                          step={0.01}
                          min={0}
                          className="w-full font-sans py-0.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                          controls={false}
                          addonBefore="$"
                          addonAfter="USD"
                          disabled={selectedSerialPart ? false : true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-6">
                    <SecondaryButton label="Cancel" callback={() => closeAddSerialPart()} />
                    <PrimaryButton label="Add Part" callback={() => submitAddSerial()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const closeEditSerial = () => {
    setEditSerial(false);
    setSerialToAdd({
      partId: "",
      partNo: "",
      quantity: 1,
      price: 0,
      costPrice: 0,
      costTotal: 0,
      total: 0,
      markup: 45,
    });
    setSelectedSerialPart(null);
    setSerialQuery("");
  };

  const editSerialOpen = (itemId) => {
    let item = serializedItems.find((i) => i.itemId === itemId);
    if (!item) {
      toast.error("Error editing part");
      return;
    }
    setSerialToAdd(item);
    setEditSerial(true);
  };

  const submitUpdateSerial = () => {
    if (serialToAdd.quantity === 0) {
      toast.error("Please enter a part quantity");
      return;
    }
    setLoading(true);
    let tmp = serializedItems.filter((pt) => pt.itemId !== serialToAdd.itemId);
    let price = serialToAdd.price === 0 ? serialToAdd.chargeOut : serialToAdd.price;
    tmp.push({
      itemId: uuidv4(),
      partId: serialToAdd.partId,
      partNo: serialToAdd.partNo,
      description: serialToAdd.description,
      quantity: serialToAdd.quantity,
      price: price,
      costPrice: serialToAdd.costPrice,
      costTotal: serialToAdd.costPrice * serialToAdd.quantity,
      total: serialToAdd.total,
      markup: serialToAdd.markup,
    });
    setSerializedItems(tmp);
    setEditSerial(false);
    toast.success("Part added, please save changes for it to take effect");
    setTimeout(() => setLoading(false), 700);
  };

  const renderEditSerial = () => {
    return (
      <Transition.Root show={editSerial} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeEditSerial}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl">
                  <div className="flex flex-col items-start justify-start w-full gap-2">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Edit A Serialized Part
                    </Dialog.Title>
                    <div className="w-full mt-2">
                      <label htmlFor="part" className="block text-sm font-medium leading-6 text-gray-900">
                        Part
                      </label>
                      <Select
                        options={parts.map((c) => ({
                          value: c.partId,
                          label: `${c.partNo}${c.description && c.description.length > 0 ? " | " + c.description : ""}`,
                        }))}
                        filterOption={filterOption}
                        className="w-full font-sans"
                        controls={false}
                        showSearch
                        placeholder="Search / Select a part"
                        onChange={pickSerialPart}
                        defaultValue={serialToAdd.partId}
                        disabled
                      />
                    </div>
                    <div className="w-full">
                      <label htmlFor="costPrice" className="block text-sm font-medium leading-6 text-gray-900">
                        Cost
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <InputNumber
                          placeholder="0.00"
                          name="costPrice"
                          onChange={(v) => editSerialPartChange(v, "costPrice")}
                          value={serialToAdd.costPrice}
                          step={0.01}
                          min={0}
                          className="w-full font-sans py-0.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                          controls={false}
                          addonBefore="$"
                          addonAfter="USD"
                        />
                      </div>
                    </div>
                    <div className="w-full">
                      <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                        Markup
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <InputNumber
                          placeholder="0.00"
                          name="markup"
                          onChange={(v) => editSerialPartChange(v, "markup")}
                          value={serialToAdd.markup}
                          step={0.01}
                          min={0}
                          className="w-full font-sans py-0.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                          controls={false}
                          addonAfter="%"
                        />
                      </div>
                    </div>
                    <div className="w-full">
                      <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                        Ext Price
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <InputNumber
                          placeholder="0.00"
                          name="price"
                          onChange={(v) => editSerialPartChange(v, "price")}
                          value={serialToAdd.price}
                          step={0.01}
                          min={0}
                          className="w-full font-sans py-0.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                          controls={false}
                          addonBefore="$"
                          addonAfter="USD"
                        />
                      </div>
                    </div>
                    <div className="w-full">
                      <label htmlFor="quantity" className="block text-sm font-medium leading-6 text-gray-900">
                        Quantity
                      </label>
                      <div className="mt-2">
                        <InputNumber
                          placeholder="0.00"
                          name="quantity"
                          onChange={(v) => editSerialPartChange(v, "quantity")}
                          value={serialToAdd.quantity}
                          step={0.01}
                          min={0}
                          className="w-full font-sans py-0.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                          controls={false}
                        />
                      </div>
                    </div>
                    <div className="w-full">
                      <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                        Total
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <InputNumber
                          placeholder="0.00"
                          name="total"
                          onChange={(v) => editSerialPartChange(v, "total")}
                          value={serialToAdd.total}
                          step={0.01}
                          min={0}
                          className="w-full font-sans py-0.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                          controls={false}
                          addonBefore="$"
                          addonAfter="USD"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-6">
                    <SecondaryButton label="Cancel" callback={() => closeEditSerial()} />
                    <PrimaryButton label="Add Part" callback={() => submitUpdateSerial()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const openAddNonSerialPart = () => {
    if (!editable.includes(po.poStatus)) {
      toast.error("This PO is not editable");
    } else if (po.vendorId && po.vendorId.length > 0) {
      setAddNonSerialModal(true);
    } else {
      toast.error("Please select a vendor first");
    }
  };

  const closeAddNonSerialPart = () => {
    setAddNonSerialModal(false);
    setNonSerialToAdd({
      partNo: "",
      description: "",
      quantity: 1,
      price: 0,
      costPrice: 0,
      costTotal: 0,
      markup: 45,
    });
  };

  const closeAddService = () => {
    setAddServiceModal(false);
    setServiceToAdd({
      partNo: "",
      description: "",
      quantity: 1,
      price: 0,
      costPrice: 0,
      costTotal: 0,
      markup: 45,
      service: true,
    });
  };

  const editNonSerialPartChange = (value, name) => {
    setDummyLoading(true);
    value = value.target.value || value;
    let tmp = nonSerialToAdd;
    let oldVal = tmp[name];
    try {
      tmp[name] = parseFloat(value);
    } catch {
      tmp[name] = oldVal;
    }
    if (name === "costPrice") {
      tmp.price = value * (1 + tmp.markup / 100);
      tmp.total = tmp.price * tmp.quantity;
    } else if (name === "markup") {
      tmp.price = tmp.costPrice * (1 + value / 100);
      tmp.total = tmp.price * tmp.quantity;
    } else if (name === "price") {
      tmp.total = value * tmp.quantity;
      tmp.markup = ((tmp.price - tmp.costPrice) / tmp.costPrice) * 100;
    } else if (name === "quantity") {
      tmp.total = value * tmp.price;
    }
    setNonSerialToAdd(tmp);
    setTimeout(() => setDummyLoading(false), 300);
  };

  const editNonSerialPartChangeTXT = (e) => {
    let { name, value } = e.target;
    let tmp = nonSerialToAdd;
    tmp[name] = value;
    setNonSerialToAdd(tmp);
  };

  const editServiceChange = (value, name) => {
    setDummyLoading(true);
    let tmp = serviceToAdd;
    let oldVal = tmp[name];
    try {
      tmp[name] = parseFloat(value);
    } catch {
      tmp[name] = oldVal;
    }
    if (name === "costPrice") {
      tmp.price = value * (1 + tmp.markup / 100);
      tmp.total = tmp.price * tmp.quantity;
    } else if (name === "markup") {
      tmp.price = tmp.costPrice * (1 + value / 100);
      tmp.total = tmp.price * tmp.quantity;
    } else if (name === "price") {
      tmp.total = value * tmp.quantity;
      tmp.markup = ((tmp.price - tmp.costPrice) / tmp.costPrice) * 100;
    } else if (name === "quantity") {
      tmp.total = value * tmp.price;
    }
    setServiceToAdd(tmp);
    setTimeout(() => setDummyLoading(false), 300);
  };

  const editServiceChangeTXT = (e) => {
    let { name, value } = e.target;
    let tmp = serviceToAdd;
    tmp[name] = value;
    setServiceToAdd(tmp);
  };

  const submitAddNonSerial = () => {
    let failed = false;
    if (nonSerialToAdd.partNo === "") {
      toast.error("Please enter a part number");
      failed = true;
    } else if (nonSerialToAdd.description === "") {
      toast.error("Please enter a part description");
      failed = true;
    } else if (nonSerialToAdd.quantity === 0) {
      toast.error("Please enter a part quantity");
      failed = true;
    }
    if (!failed) {
      setLoading(true);
      let tmp = nonSerializedItems;
      tmp.push({
        itemId: uuidv4(),
        partId: "",
        partNo: nonSerialToAdd.partNo,
        description: nonSerialToAdd.description,
        quantity: nonSerialToAdd.quantity,
        price: nonSerialToAdd.price,
        costPrice: nonSerialToAdd.costPrice,
        costTotal: nonSerialToAdd.costPrice * nonSerialToAdd.quantity,
        total: nonSerialToAdd.total,
        markup: nonSerialToAdd.markup,
      });
      setNonSerializedItems(tmp);
      closeAddNonSerialPart();
      toast.success("Part added, please save changes for it to take effect");
      setTimeout(() => setLoading(false), 700);
    }
  };

  const submitAddService = () => {
    let failed = false;
    if (serviceToAdd.partNo === "") {
      toast.error("Please enter a Service ID");
      failed = true;
    } else if (serviceToAdd.description === "") {
      toast.error("Please enter a service description");
      failed = true;
    } else if (serviceToAdd.quantity === 0) {
      toast.error("Please enter a service quantity");
      failed = true;
    }
    if (!failed) {
      setLoading(true);
      let tmp = services;
      tmp.push({
        itemId: uuidv4(),
        partId: "",
        partNo: serviceToAdd.partNo,
        description: serviceToAdd.description,
        quantity: serviceToAdd.quantity,
        price: serviceToAdd.price,
        costPrice: serviceToAdd.costPrice,
        costTotal: serviceToAdd.costPrice * serviceToAdd.quantity,
        total: serviceToAdd.total,
        markup: serviceToAdd.markup,
        service: true,
      });
      setServices(tmp);
      closeAddService();
      toast.success("Service added, please save changes for it to take effect");
      setTimeout(() => setLoading(false), 700);
    }
  };

  const renderAddNonSerial = () => {
    return (
      <Modal title="Add a Non-Serialized Part" onCancel={closeAddNonSerialPart} open={addNonSerialModal} footer={[]} centered destroyOnClose width={680}>
        <div className="flex flex-col items-start justify-start w-full gap-2">
          <div key="partNo" className="flex flex-col items-start justify-start w-full">
            <label htmlFor="partNo" className="pb-1 text-xs text-gray-600 uppercase">
              Part No.
            </label>
            <Input
              placeholder="Part No"
              name="partNo"
              id="partNo"
              onChange={(e) => editNonSerialPartChangeTXT(e)}
              className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
            />
          </div>
          <div key="description" className="flex flex-col items-start justify-start w-full">
            <label htmlFor="description" className="pb-1 text-xs text-gray-600 uppercase">
              Description
            </label>
            <Input
              placeholder="Description"
              name="description"
              id="description"
              onChange={(e) => editNonSerialPartChangeTXT(e)}
              className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
            />
          </div>
          <div className="w-full">
            <label htmlFor="costPrice" className="block text-sm font-medium leading-6 text-gray-900">
              Cost
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <InputNumber
                placeholder="0.00"
                name="costPrice"
                onBlur={(v) => editNonSerialPartChange(v, "costPrice")}
                value={nonSerialToAdd.costPrice}
                step={0.01}
                min={0}
                className="w-full font-sans py-0.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                controls={false}
                addonBefore="$"
                addonAfter="USD"
              />
            </div>
          </div>
          <div className="w-full">
            <label htmlFor="markup" className="block text-sm font-medium leading-6 text-gray-900">
              Markup
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <InputNumber
                placeholder="0.00"
                name="markup"
                onBlur={(v) => editNonSerialPartChange(v, "markup")}
                value={nonSerialToAdd.markup}
                step={0.01}
                min={0}
                className="w-full font-sans py-0.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                controls={false}
                addonAfter="%"
              />
            </div>
          </div>
          <div className="w-full">
            <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
              Price
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <InputNumber
                placeholder="0.00"
                name="price"
                onBlur={(v) => editNonSerialPartChange(v, "price")}
                value={nonSerialToAdd.price}
                step={0.01}
                min={0}
                className="w-full font-sans py-0.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                controls={false}
                addonBefore="$"
                addonAfter="USD"
              />
            </div>
          </div>
          <div className="w-full">
            <label htmlFor="quantity" className="block text-sm font-medium leading-6 text-gray-900">
              Quantity
            </label>
            <div className="mt-2">
              <InputNumber
                placeholder="Quantity"
                name="quantity"
                onBlur={(v) => editNonSerialPartChange(v, "quantity")}
                value={nonSerialToAdd.quantity}
                step={0.01}
                min={0}
                className="w-full font-sans py-0.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                controls={false}
              />
            </div>
          </div>
          <div className="w-full">
            <label htmlFor="total" className="block text-sm font-medium leading-6 text-gray-900">
              Total
            </label>
            <div className="mt-2">
              <InputNumber
                placeholder="Total"
                name="total"
                onBlur={(v) => editNonSerialPartChange(v, "total")}
                value={nonSerialToAdd.total}
                step={0.01}
                min={0}
                className="w-full font-sans py-0.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                controls={false}
                addonBefore="$"
                addonAfter="USD"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-end w-full gap-2 mt-6">
          <SecondaryButton label="Cancel" callback={() => closeAddNonSerialPart()} />
          <PrimaryButton label="Add Part" callback={() => submitAddNonSerial()} />
        </div>
      </Modal>
    );
  };

  const openEditNonSerial = (itemId) => {
    let item = nonSerializedItems.find((i) => i.itemId === itemId);
    if (!item) {
      toast.error("Error editing part");
      return;
    }
    setNonSerialToAdd(item);
    setEditNonSerial(true);
  };

  const closeEditNonSerial = () => {
    setEditNonSerial(false);
    setNonSerialToAdd({
      partNo: "",
      description: "",
      quantity: 1,
      price: 0,
      costPrice: 0,
      costTotal: 0,
      markup: 45,
    });
  };

  const submitEditNonSerial = () => {
    if (nonSerialToAdd.partNo === "") {
      toast.error("Please enter a part number");
      return;
    } else if (nonSerialToAdd.description === "") {
      toast.error("Please enter a part description");
      return;
    } else if (nonSerialToAdd.quantity === 0) {
      toast.error("Please enter a part quantity");
      return;
    }
    setLoading(true);
    let tmp = nonSerializedItems.filter((pt) => pt.itemId !== nonSerialToAdd.itemId);
    tmp.push({
      itemId: nonSerialToAdd.itemId,
      partId: "",
      partNo: nonSerialToAdd.partNo,
      description: nonSerialToAdd.description,
      quantity: nonSerialToAdd.quantity,
      price: nonSerialToAdd.price,
      costPrice: nonSerialToAdd.costPrice,
      costTotal: nonSerialToAdd.costPrice * nonSerialToAdd.quantity,
      total: nonSerialToAdd.total,
      markup: nonSerialToAdd.markup === 0 ? 1 : nonSerialToAdd.markup,
    });
    setNonSerializedItems(tmp);
    closeEditNonSerial();
    toast.success("Part edited, please save changes for it to take effect");
    setTimeout(() => setLoading(false), 700);
  };

  const renderEditNonSerial = () => {
    return (
      <Modal title="Edit a Non-Serialized Part" onCancel={closeEditNonSerial} open={editNonSerial} footer={[]} centered destroyOnClose width={680}>
        <div className="flex flex-col items-start justify-start w-full gap-2">
          <div key="partNo" className="flex flex-col items-start justify-start w-full">
            <label htmlFor="partNo" className="pb-1 text-xs text-gray-600 uppercase">
              Part No.
            </label>
            <Input
              placeholder="Part No"
              name="partNo"
              id="partNo"
              onChange={(e) => editNonSerialPartChangeTXT(e)}
              defaultValue={nonSerialToAdd.partNo}
              className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
            />
          </div>
          <div key="description" className="flex flex-col items-start justify-start w-full">
            <label htmlFor="description" className="pb-1 text-xs text-gray-600 uppercase">
              Description
            </label>
            <Input
              placeholder="Description"
              name="description"
              id="description"
              onChange={(e) => editNonSerialPartChangeTXT(e)}
              defaultValue={nonSerialToAdd.description}
              className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
            />
          </div>
          <div className="w-full">
            <label htmlFor="costPrice" className="block text-sm font-medium leading-6 text-gray-900">
              Cost
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <InputNumber
                placeholder="0.00"
                name="costPrice"
                onBlur={(v) => editNonSerialPartChange(v, "costPrice")}
                value={nonSerialToAdd.costPrice}
                step={0.01}
                min={0}
                className="w-full font-sans py-0.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                controls={false}
                addonBefore="$"
                addonAfter="USD"
              />
            </div>
          </div>
          <div className="w-full">
            <label htmlFor="markup" className="block text-sm font-medium leading-6 text-gray-900">
              Markup
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <InputNumber
                placeholder="0.00"
                name="markup"
                onBlur={(v) => editNonSerialPartChange(v, "markup")}
                value={nonSerialToAdd.markup}
                step={0.01}
                min={0}
                className="w-full font-sans py-0.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                controls={false}
                addonAfter="%"
              />
            </div>
          </div>
          <div className="w-full">
            <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
              Price
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <InputNumber
                placeholder="0.00"
                name="price"
                onBlur={(v) => editNonSerialPartChange(v, "price")}
                value={nonSerialToAdd.price}
                step={0.01}
                min={0}
                className="w-full font-sans py-0.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                controls={false}
                addonBefore="$"
                addonAfter="USD"
              />
            </div>
          </div>
          <div className="w-full">
            <label htmlFor="quantity" className="block text-sm font-medium leading-6 text-gray-900">
              Quantity
            </label>
            <div className="mt-2">
              <InputNumber
                placeholder="Quantity"
                name="quantity"
                onBlur={(v) => editNonSerialPartChange(v, "quantity")}
                value={nonSerialToAdd.quantity}
                step={0.01}
                min={0}
                className="w-full font-sans py-0.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                controls={false}
              />
            </div>
          </div>
          <div className="w-full">
            <label htmlFor="total" className="block text-sm font-medium leading-6 text-gray-900">
              Total
            </label>
            <div className="mt-2">
              <InputNumber
                placeholder="Total"
                name="total"
                onBlur={(v) => editNonSerialPartChange(v, "total")}
                value={nonSerialToAdd.total}
                step={0.01}
                min={0}
                className="w-full font-sans py-0.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                controls={false}
                addonBefore="$"
                addonAfter="USD"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-end w-full gap-2 mt-6">
          <SecondaryButton label="Cancel" callback={() => closeEditNonSerial()} />
          <PrimaryButton label="Add Part" callback={() => submitEditNonSerial()} />
        </div>
      </Modal>
    );
  };

  const renderAddService = () => {
    return (
      <Transition.Root show={addServiceModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeAddService}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl">
                  <div className="flex flex-col items-start justify-start w-full gap-2">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Add A Service to PO
                    </Dialog.Title>
                    <div key="partNo" className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="partNo" className="pb-1 text-xs text-gray-600 uppercase">
                        Service ID
                      </label>
                      <input
                        className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed"
                        type="text"
                        id="partNo"
                        name="partNo"
                        onChange={(e) => editServiceChangeTXT(e)}
                      />
                    </div>
                    <div key="description" className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="description" className="pb-1 text-xs text-gray-600 uppercase">
                        Description
                      </label>
                      <input
                        className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed"
                        type="text"
                        id="description"
                        name="description"
                        onChange={(e) => editServiceChangeTXT(e)}
                      />
                    </div>
                    <div className="w-full">
                      <label htmlFor="costPrice" className="block text-sm font-medium leading-6 text-gray-900">
                        Cost
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <InputNumber
                          placeholder="0.00"
                          name="costPrice"
                          onChange={(v) => editServiceChange(v, "costPrice")}
                          value={serviceToAdd.costPrice}
                          step={0.01}
                          min={0}
                          className="w-full font-sans py-0.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                          controls={false}
                          addonBefore="$"
                          addonAfter="USD"
                        />
                      </div>
                    </div>
                    <div className="w-full">
                      <label htmlFor="markup" className="block text-sm font-medium leading-6 text-gray-900">
                        Markup
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <InputNumber
                          placeholder="0.00"
                          name="markup"
                          onChange={(v) => editServiceChange(v, "markup")}
                          value={serviceToAdd.markup}
                          step={0.01}
                          min={0}
                          className="w-full font-sans py-0.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                          controls={false}
                          addonAfter="%"
                        />
                      </div>
                    </div>
                    <div className="w-full">
                      <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                        Price
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <InputNumber
                          placeholder="0.00"
                          name="price"
                          onChange={(v) => editServiceChange(v, "price")}
                          value={serviceToAdd.price}
                          step={0.01}
                          min={0}
                          className="w-full font-sans py-0.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                          controls={false}
                          addonBefore="$"
                          addonAfter="USD"
                        />
                      </div>
                    </div>
                    <div className="w-full">
                      <label htmlFor="quantity" className="block text-sm font-medium leading-6 text-gray-900">
                        Quantity
                      </label>
                      <div className="mt-2">
                        <InputNumber
                          placeholder="Quantity"
                          name="quantity"
                          onChange={(v) => editServiceChange(v, "quantity")}
                          value={serviceToAdd.quantity}
                          step={0.01}
                          min={0}
                          className="w-full font-sans py-0.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                          controls={false}
                        />
                      </div>
                    </div>
                    <div className="w-full">
                      <label htmlFor="total" className="block text-sm font-medium leading-6 text-gray-900">
                        Total
                      </label>
                      <div className="mt-2">
                        <InputNumber
                          placeholder="Total"
                          name="total"
                          onChange={(v) => editServiceChange(v, "total")}
                          value={serviceToAdd.total}
                          step={0.01}
                          min={0}
                          className="w-full font-sans py-0.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                          controls={false}
                          addonBefore="$"
                          addonAfter="USD"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-6">
                    <SecondaryButton label="Cancel" callback={() => closeAddService()} />
                    <PrimaryButton label="Add Service" callback={() => submitAddService()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const openEditService = (itemId) => {
    let item = services.find((i) => i.itemId === itemId);
    if (!item) {
      toast.error("Error editing service");
      return;
    }
    setServiceToAdd(item);
    setEditService(true);
  };

  const closeEditService = () => {
    setEditService(false);
    setServiceToAdd({
      partNo: "",
      description: "",
      quantity: 1,
      price: 0,
      costPrice: 0,
      costTotal: 0,
      markup: 45,
      service: true,
    });
  };

  const submitEditService = () => {
    if (serviceToAdd.partNo === "") {
      toast.error("Please enter a Service ID");
      return;
    } else if (serviceToAdd.description === "") {
      toast.error("Please enter a service description");
      return;
    } else if (serviceToAdd.quantity === 0) {
      toast.error("Please enter a service quantity");
      return;
    }
    setLoading(true);
    let tmp = services.filter((s) => s.itemId !== serviceToAdd.itemId);
    tmp.push({
      itemId: serviceToAdd.itemId,
      partId: "",
      partNo: serviceToAdd.partNo,
      description: serviceToAdd.description,
      quantity: serviceToAdd.quantity,
      price: serviceToAdd.price,
      costPrice: serviceToAdd.costPrice,
      costTotal: serviceToAdd.costPrice * serviceToAdd.quantity,
      total: serviceToAdd.total,
      markup: serviceToAdd.markup,
      service: true,
    });
    setServices(tmp);
    closeEditService();
    toast.success("Service updated, please save changes for it to take effect");
    setTimeout(() => setLoading(false), 700);
  };

  const renderEditService = () => {
    return (
      <Transition.Root show={editService} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeEditService}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl">
                  <div className="flex flex-col items-start justify-start w-full gap-2">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Edit Service
                    </Dialog.Title>
                    <div key="partNo" className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="partNo" className="pb-1 text-xs text-gray-600 uppercase">
                        Service ID
                      </label>
                      <input
                        className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed"
                        type="text"
                        id="partNo"
                        name="partNo"
                        onChange={(e) => editServiceChangeTXT(e)}
                        defaultValue={serviceToAdd.partNo}
                      />
                    </div>
                    <div key="description" className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="description" className="pb-1 text-xs text-gray-600 uppercase">
                        Description
                      </label>
                      <input
                        className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed"
                        type="text"
                        id="description"
                        name="description"
                        onChange={(e) => editServiceChangeTXT(e)}
                        defaultValue={serviceToAdd.description}
                      />
                    </div>
                    <div className="w-full">
                      <label htmlFor="costPrice" className="block text-sm font-medium leading-6 text-gray-900">
                        Cost
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <InputNumber
                          placeholder="0.00"
                          name="costPrice"
                          onChange={(v) => editServiceChange(v, "costPrice")}
                          value={serviceToAdd.costPrice}
                          step={0.01}
                          min={0}
                          className="w-full font-sans py-0.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                          controls={false}
                          addonBefore="$"
                          addonAfter="USD"
                        />
                      </div>
                    </div>
                    <div className="w-full">
                      <label htmlFor="markup" className="block text-sm font-medium leading-6 text-gray-900">
                        Markup
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <InputNumber
                          placeholder="0.00"
                          name="markup"
                          onChange={(v) => editServiceChange(v, "markup")}
                          value={serviceToAdd.markup}
                          step={0.01}
                          min={0}
                          className="w-full font-sans py-0.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                          controls={false}
                          addonAfter="%"
                        />
                      </div>
                    </div>
                    <div className="w-full">
                      <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                        Price
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <InputNumber
                          placeholder="0.00"
                          name="price"
                          onChange={(v) => editServiceChange(v, "price")}
                          value={serviceToAdd.price}
                          step={0.01}
                          min={0}
                          className="w-full font-sans py-0.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                          controls={false}
                          addonBefore="$"
                          addonAfter="USD"
                        />
                      </div>
                    </div>
                    <div className="w-full">
                      <label htmlFor="quantity" className="block text-sm font-medium leading-6 text-gray-900">
                        Quantity
                      </label>
                      <div className="mt-2">
                        <InputNumber
                          placeholder="Quantity"
                          name="quantity"
                          onChange={(v) => editServiceChange(v, "quantity")}
                          value={serviceToAdd.quantity}
                          step={0.01}
                          min={0}
                          className="w-full font-sans py-0.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                          controls={false}
                        />
                      </div>
                    </div>
                    <div className="w-full">
                      <label htmlFor="total" className="block text-sm font-medium leading-6 text-gray-900">
                        Total
                      </label>
                      <div className="mt-2">
                        <InputNumber
                          placeholder="Total"
                          name="total"
                          onChange={(v) => editServiceChange(v, "total")}
                          value={serviceToAdd.total}
                          step={0.01}
                          min={0}
                          className="w-full font-sans py-0.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                          controls={false}
                          addonBefore="$"
                          addonAfter="USD"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-6">
                    <SecondaryButton label="Cancel" callback={() => closeEditService()} />
                    <PrimaryButton label="Add Service" callback={() => submitEditService()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const saveChanges = () => {
    let tmp = {
      items: [],
    };
    let tempItems = [];
    for (let i = 0; i < serializedItems.length; i++) {
      let item = serializedItems[i];
      if (item.itemId && item.quantity > 0) {
        tempItems.push(item);
      }
    }
    for (let i = 0; i < nonSerializedItems.length; i++) {
      let item = nonSerializedItems[i];
      if (item.itemId && item.quantity > 0) {
        tempItems.push(item);
      }
    }
    for (let i = 0; i < services.length; i++) {
      let item = services[i];
      if (item.itemId && item.quantity > 0) {
        tempItems.push(item);
      }
    }
    tmp.items = tempItems;
    if (isDirty) {
      let dirtyOnes = dirtyFields;
      for (let property in dirtyOnes) {
        if (property === "talkedTo") {
          let val = getValues(property);
          if (val.length < 3) {
            setError(property, {
              type: "custom",
              message: "Who did you talk to / How did you contact the vendor?",
            });
          } else {
            tmp[property] = val;
          }
        } else {
          tmp[property] = getValues(property);
        }
      }
    }
    setLoading(true);
    UpdatePurchaseOrder(poId, tmp)
      .then((res) => {
        toast.success("Purchase order updated");
        reloadData();
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error updating purchase order");
        setLoading(false);
      });
  };

  const approvePO = () => {
    // let tmp = {
    //   items: [],
    // };
    // for (let i = 0; i < serializedItems.length; i++) {
    //   let item = serializedItems[i];
    //   tmp.items.push(item);
    // }
    // for (let i = 0; i < nonSerializedItems.length; i++) {
    //   let item = nonSerializedItems[i];
    //   tmp.items.push(item);
    // }
    // if (isDirty) {
    //   let dirtyOnes = dirtyFields;
    //   for (let property in dirtyOnes) {
    //     if (property === "talkedTo") {
    //       let val = getValues(property);
    //       if (val.length < 3) {
    //         setError(property, {
    //           type: "custom",
    //           message: "Who did you talk to / How did you contact the vendor?",
    //         });
    //       } else {
    //         tmp[property] = val;
    //       }
    //     } else {
    //       tmp[property] = getValues(property);
    //     }
    //   }
    // }
    // if (count === tmp.items.length) {
    postApprovePO();
    // } else {
    //   setLoading(true);
    //   UpdatePurchaseOrder(poId, tmp)
    //     .then((res) => {
    //       setLoading(false);
    //       postApprovePO();
    //     })
    //     .catch((err) => {
    //       toast.error(err.response.data.message ? err.response.data.message : "Error updating purchase order");
    //       setLoading(false);
    //     });
    // }
  };

  const printPOPreview = () => {
    let tmp = getValues();
    tmp.items = [];
    for (let i = 0; i < serializedItems.length; i++) {
      tmp.items.push(serializedItems[i]);
    }
    for (let i = 0; i < nonSerializedItems.length; i++) {
      tmp.items.push(nonSerializedItems[i]);
    }
    for (let i = 0; i < services.length; i++) {
      tmp.items.push(services[i]);
    }
    if (tmp.jobReference && tmp.jobReference.length > 0) {
      tmp.jobReference = "JOB " + jobs.find((j) => j.jobId === tmp.jobReference).jobNo;
    } else {
      tmp.jobReference = "N/A";
    }
    tmp.poNumber = po.poNumber;
    let forVals = {
      rnm: "R&M",
      inventory: "Inventory",
      general: "General",
      job: "Job",
    };
    tmp.partsFor = forVals[po.partsFor];
    if (tmp.poAccount.length > 0) {
      tmp.poAccount = accounts.find((j) => j.accountId === tmp.poAccount).accountNo + " | " + accounts.find((j) => j.accountId === tmp.poAccount).description;
    } else {
      tmp.poAccount = "N/A";
    }
    if (tmp.vendorId.length > 0) {
      tmp.vendorName = " | " + vendors.find((j) => j.vendorId === tmp.vendorId).vendorName;
      tmp.vendorCode = vendors.find((j) => j.vendorId === tmp.vendorId).vendorCode;
    } else {
      tmp.vendorCode = "N/A";
      tmp.vendorName = "";
    }
    tmp.poDate = po.poDate;
    if (tmp.locationId && tmp.locationId.length > 0) {
      tmp.location = locations.find((l) => l.locationId === tmp.locationId).locationName;
    } else {
      tmp.location = "Unknown";
    }
    tmp.tax = po.tax;
    tmp.notes = po.notes;
    if (customer) {
      tmp.customerInfo = customer;
    }
    let doc = generatePoPreview(tmp);
    doc.setProperties({
      title: `PO Preview - ${po.poNumber}`,
      subject: `PO Preview - ${po.poNumber}`,
      author: "Hypertek Solutions LLC",
      keywords: "",
      creator: "contact@hypertek.dev",
    });
    window.open(doc.output("bloburl"), "_blank");
  };

  const postApprovePO = () => {
    setModalType("Approve");
    setTimeout(() => setModalOpen(true), 300);
  };

  const denyPO = () => {
    setModalType("Reject");
    setTimeout(() => setModalOpen(true), 300);
  };

  const voidPO = () => {
    setModalType("VOID");
    setTimeout(() => setModalOpen(true), 300);
  };

  const renderVoidApproveDenyModal = () => {
    return (
      <Transition.Root show={modalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-md">
                  <div className="flex flex-col items-start justify-start gap-2">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Are you sure you want to {modalType} this PO?
                    </Dialog.Title>
                    <div className="w-full mt-1">
                      {modalType === "VOID" ? (
                        <p className="text-sm text-gray-500">
                          Once you VOID this PO, it will not be able to make additional changes. This action is permanent and irreversible.
                        </p>
                      ) : null}
                      <p className="mt-1 text-sm text-gray-500">
                        {modalType === "Reject"
                          ? "Please provide a reason for rejecting this PO."
                          : modalType === "VOID"
                            ? "Please provide a reason for voiding this PO."
                            : "Please provide a reason for approving this PO."}
                      </p>

                      <label htmlFor="comment" className="block mt-3 text-sm font-medium leading-6 text-gray-900">
                        Add your comment
                      </label>
                      <div className="w-full mt-2">
                        <textarea
                          rows={4}
                          name="comment"
                          id="comment"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 resize-none"
                          onChange={(e) => setComment(e.target.value)}
                          defaultValue={""}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-6">
                    <SecondaryButton label="Cancel" callback={() => closeModal()} />
                    <PrimaryButton label={`${modalType} PO`} callback={() => submitModal()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const submitModal = () => {
    setLoading(true);
    if (modalType === "VOID") {
      VOIDPurchaseOrder(poId, { comment: comment })
        .then((res) => {
          toast.success("Purchase order voided");
          closeModal();
          reloadData();
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error voiding purchase order");
          setLoading(false);
        });
    } else if (modalType === "Approve") {
      ApprovePO(poId, { comment: comment })
        .then((res) => {
          toast.success("Purchase order approved");
          toast("Any further changes to this PO will put it back into draft status");
          closeModal();
          reloadData();
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error approving purchase order");
          setLoading(false);
        });
    } else if (modalType === "Reject") {
      DenyPO(poId, { comment: comment })
        .then((res) => {
          toast.success("Purchase order rejected");
          toast("Any further changes to this PO will put it back into draft status");
          closeModal();
          reloadData();
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error rejecting purchase order");
          setLoading(false);
        });
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setTimeout(() => {
      setComment("");
      setModalType("");
    }, 300);
  };

  const negotiatePOSubmission = () => {
    if (po.expectedDeliveryDate && po.expectedDeliveryDate.length > 0) {
      let tmp = {
        dateSent: po.dateSent ?? dayjs().toJSON(),
        expectedDeliveryDate: po.expectedDeliveryDate,
      };
      setLoading(true);
      SubmitPO(poId, tmp)
        .then((res) => {
          toast.success("Purchase order marked as submitted to vendor");
          closeSubmitPOModal();
          reloadData();
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error submitting purchase order");
          setLoading(false);
        });
    } else {
      setSubmitPOModal(true);
    }
  };

  const renderPOActions = () => {
    if (po.poStatus === "draft") {
      return (
        <>
          <SecondaryButton label="VOID" callback={() => voidPO()} />
          <SecondaryButton label="Reject" callback={() => denyPO()} />
          <SecondaryButton label="Approve" callback={() => approvePO()} />
          <PrimaryButton label="Save Changes" callback={() => saveChanges()} />
        </>
      );
    } else if (po.poStatus === "approved") {
      return (
        <>
          <SecondaryButton label="VOID" callback={() => voidPO()} />
          <PrimaryButton label="Mark PO as Submitted" callback={() => negotiatePOSubmission()} />
          <PrimaryButton label="Save Changes" callback={() => saveChanges()} />
        </>
      );
    } else if (po.poStatus === "denied") {
      return (
        <>
          <SecondaryButton label="VOID" callback={() => voidPO()} />
          <PrimaryButton label="Save Changes" callback={() => saveChanges()} />
        </>
      );
    } else if (po.poStatus === "submitted" || (po.poStatus === "backOrdered" && po.invoiceId.length === 0)) {
      return (
        <>
          <SecondaryButton label="VOID" callback={() => voidPO()} />
          {!po.invoiceId || po.invoiceId.length === "" || !validateUUID(po.invoiceId) ? (
            <PrimaryButton label="Enter Invoice" callback={() => enterInvoice()} />
          ) : (
            <PrimaryButton label="View Invoice" callback={() => window.open(`/ap/${po.invoiceId}`, "_blank")} />
          )}
          <PrimaryButton label="Receive Parts" callback={() => openReceivePartsModal()} />
          <PrimaryButton label="Save Changes" callback={() => saveChanges()} />
        </>
      );
    } else if (po.poStatus === "received") {
      if (!po.invoiceId || po.invoiceId.length === "" || !validateUUID(po.invoiceId)) {
        return (
          <>
            <PrimaryButton label="Enter Invoice" callback={() => enterInvoice()} />
            <PrimaryButton label="Save Changes" callback={() => saveChanges()} />
          </>
        );
      } else if (po.invoiceId.length !== "") {
        return (
          <>
            <SecondaryButton label="View Invoice" callback={() => window.open(`/ap/${po.invoiceId}`, "_blank").focus()} />
            ;
            <PrimaryButton label="Save Changes" callback={() => saveChanges()} />
          </>
        );
      }
    } else if ((po.poStatus === "invoiced" && !dayjs(po.dateReceived).isValid()) || (po.poStatus === "backOrdered" && po.invoiceId.length !== 0)) {
      return (
        <>
          {po.invoiceId && po.invoiceId.length > 5 ? (
            <SecondaryButton label="View Invoice" callback={() => window.open(`/ap/${po.invoiceId}`, "_blank").focus()} />
          ) : (
            <SecondaryButton label="Enter Invoice" callback={() => enterInvoice()} />
          )}
          <PrimaryButton label="Receive Parts" callback={() => openReceivePartsModal()} />
          <PrimaryButton label="Save Changes" callback={() => saveChanges()} />
        </>
      );
    }
  };

  const closeSubmitPOModal = () => {
    setSubmitPOModal(false);
    setSubmitData({
      expectedDeliveryDate: "",
      dateSent: "",
    });
  };

  const updateDateSent = (date, dateString) => {
    let tmp = submitData;
    if (dateString === "") {
      tmp.dateSent = "";
    } else {
      tmp.dateSent = date.toJSON();
    }
    setSubmitData(tmp);
  };

  const updateExpectedDeliveryDate = (date, dateString) => {
    let tmp = submitData;
    if (dateString === "") {
      tmp.expectedDeliveryDate = "";
    } else {
      tmp.expectedDeliveryDate = date.toJSON();
    }
    setSubmitData(tmp);
  };

  const renderSubmitPOModal = () => {
    return (
      <Transition.Root show={submitPOModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeSubmitPOModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="flex flex-col items-start justify-start gap-2">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Mark PO as Submitted to Vendor
                    </Dialog.Title>
                    <div className="flex flex-col items-start justify-start w-full gap-2 mt-2">
                      <div className="flex flex-row items-center justify-between w-full">
                        <p className="text-sm font-medium">Date PO Was Submitted:</p>
                        <DatePicker style={{ width: "50%" }} onChange={updateDateSent} format={"MM/DD/YYYY"} />
                      </div>
                      <div className="flex flex-row items-center justify-between w-full">
                        <p className="text-sm font-medium">Expected Delivery Date:</p>
                        <DatePicker style={{ width: "50%" }} onChange={updateExpectedDeliveryDate} format={"MM/DD/YYYY"} />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-6">
                    <SecondaryButton label="Cancel" callback={() => closeSubmitPOModal()} />
                    <PrimaryButton label="Mark PO as Submitted" callback={() => submitToVendor()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const submitToVendor = () => {
    let tmp = submitData;
    if (tmp.dateSent === "") {
      tmp.dateSent = dayjs().toJSON();
    }
    setLoading(true);
    SubmitPO(poId, tmp)
      .then((res) => {
        toast.success("Purchase order marked as submitted to vendor");
        closeSubmitPOModal();
        reloadData();
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error submitting purchase order");
        setLoading(false);
      });
  };

  const openReceivePartsModal = () => {
    let tmp = [];
    for (let i = 0; i < po.items.length; i++) {
      tmp.push({
        itemId: po.items[i].itemId,
        description: po.items[i].description,
        quantity: po.items[i].quantity,
        received: po.items[i].received,
        partNo: po.items[i].partNo,
      });
    }
    setReceivedParts(tmp);
    setTimeout(() => setReceivePartsModal(true), 300);
  };

  const closeReceivePartsModal = () => {
    setReceivePartsModal(false);
    setReceivedParts([]);
  };

  const changeReceivedPartQuantity = (value, id) => {
    setDummyLoading(true);
    let tmp = receivedParts;
    if (value !== "0" || value !== "") {
      let existing = _.find(tmp, (p) => p.itemId === id);
      if (existing && existing !== undefined) {
        let index = _.findIndex(tmp, (p) => p.itemId === existing.itemId);
        if (parseFloat(value) > parseFloat(tmp[index].quantity)) {
          toast.error("Received quantity cannot be greater than ordered quantity");
        } else {
          tmp[index].received = value;
          setReceivedParts(tmp);
        }
      } else {
        toast.error("Error updating received quantity");
      }
    }
    setTimeout(() => setDummyLoading(false), 300);
  };

  const renderReceivedPartsModal = () => {
    return (
      <Transition.Root show={receivePartsModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeReceivePartsModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-4xl">
                  <div className="flex flex-col items-start justify-start w-full gap-2 mt-3">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Receive Parts
                    </Dialog.Title>
                    <table className="min-w-full overflow-y-auto divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                            Part No
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Description
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Ordered Qty
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900 w-[200px]">
                            Received Qty
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {receivedParts.map((pt) => (
                          <tr key={uuidv4()} className="h-12">
                            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-0">{pt.partNo}</td>
                            <td className="px-3 py-4 text-sm text-gray-500 capitalize truncate whitespace-nowrap">{`${pt.description.slice(
                              0,
                              20,
                            )}${pt.description.length > 20 ? "..." : ""}`}</td>
                            <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{pt.quantity}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[200px]">
                              <InputNumber
                                name={pt.itemId}
                                id={pt.itemId}
                                onChange={(v) => changeReceivedPartQuantity(v, pt.itemId)}
                                defaultValue={pt.received}
                                max={pt.quantity}
                                min={0}
                                className="w-full font-sans py-0.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                                controls={false}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-6">
                    <SecondaryButton label="Cancel" callback={() => closeReceivePartsModal()} />
                    <PrimaryButton label="Receive Parts" callback={() => receiveParts()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const receiveParts = () => {
    let tmp = [];
    let totalReceived = 0;
    for (let i = 0; i < receivedParts.length; i++) {
      const element = receivedParts[i];
      tmp.push({
        itemId: element.itemId,
        received: parseFloat(element.received),
      });
      totalReceived += parseFloat(element.received);
    }
    if (totalReceived > 0) {
      setLoading(true);
      ReceivePO(poId, tmp)
        .then((res) => {
          toast.success("Parts received");
          closeReceivePartsModal();
          reloadData();
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error receiving parts");
          setLoading(false);
        });
    } else {
      toast.error("You must receive at least one part");
    }
  };

  const closeInvoiceModal = () => {
    setInvoiceModal(false);
    setInvoiceData({
      invoiceNumber: "",
      invoiceDate: "",
      dueDate: "",
      items: [],
      subtotal: 0,
      shipping: 0,
      tax: 0,
      total: 0,
      account: null,
    });
  };

  const submitInvoice = () => {
    let failed = false;
    if (invoiceData.invoiceNumber === "") {
      failed = true;
      toast.error("Invoice number cannot be empty");
    } else if (invoiceData.invoiceDate === "") {
      failed = true;
      toast.error("Invoice date cannot be empty");
    } else if (invoiceData.dueDate === "") {
      failed = true;
      toast.error("Due date cannot be empty");
    } else if (invoiceData.items.length === 0) {
      failed = true;
      toast.error("Invoice must have at least one item");
    } else if (!invoiceData.account) {
      failed = true;
      toast.error("Invoice must have an AP account selected");
    }
    if (!failed) {
      setLoading(true);
      SubmitPOInvoice(poId, invoiceData)
        .then((res) => {
          toast.success("PO Invoice submitted successfully!");
          closeInvoiceModal();
          window.open(`/ap/${res.data.invoiceId}`, "_blank").focus();
          reloadData();
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Error submitting PO Invoice");
          setLoading(false);
        });
    }
  };

  const enterInvoice = () => {
    let tmp = invoiceData;
    tmp.items = po.items;
    let subtotal = tmp.items.reduce((acc, item) => acc + item.costTotal, 0);
    tmp.items = po.items.map((item) => ({ ...item, total: item.costTotal, price: item.costPrice }));
    tmp.subtotal = parseFloat(subtotal.toFixed(2));
    tmp.shipping = po.shipping;
    tmp.total = parseFloat((parseFloat(subtotal.toFixed(2)) + tmp.shipping).toFixed(2));
    if (po.poAccount && validateUUID(po.poAccount)) {
      tmp.account = po.poAccount;
    }
    setInvoiceData(tmp);
    setTimeout(() => setInvoiceModal(true), 300);
  };

  const changeInvoicePartQuantity = (e) => {
    setDummyLoading(true);
    let tmp = invoiceData.items;
    let { id, value } = e.target;
    if (value !== "0" || value !== "") {
      let existing = _.find(tmp, (p) => p.itemId === id);
      if (existing && existing !== undefined) {
        let index = _.findIndex(tmp, (p) => p.itemId === existing.itemId);
        tmp[index].quantity = parseFloat(value);
        tmp[index].total = parseFloat(tmp[index].quantity) * parseFloat(tmp[index].price);
        let preTemp = invoiceData;
        invoiceData.items = tmp;
        let subtotal = 0;
        for (let i = 0; i < tmp.length; i++) {
          subtotal += parseFloat(tmp[i].total);
        }
        preTemp.subtotal = subtotal;
        preTemp.total = parseFloat(preTemp.subtotal) + parseFloat(preTemp.shipping) + parseFloat(preTemp.tax);
        setInvoiceData(preTemp);
      } else {
        toast.error("Error updating received quantity");
      }
    }
    setTimeout(() => setDummyLoading(false), 300);
  };

  const changeInvoicePartPrice = (e) => {
    setDummyLoading(true);
    let tmp = invoiceData.items;
    let { id, value } = e.target;
    if (value !== "0" || value !== "") {
      let existing = _.find(tmp, (p) => p.itemId === id);
      if (existing && existing !== undefined) {
        let index = _.findIndex(tmp, (p) => p.itemId === existing.itemId);
        tmp[index].price = parseFloat(value);
        tmp[index].total = parseFloat(tmp[index].quantity) * parseFloat(tmp[index].price);
        let preTemp = invoiceData;
        invoiceData.items = tmp;
        setInvoiceData(preTemp);
      } else {
        toast.error("Error updating received quantity");
      }
    }
    setTimeout(() => setDummyLoading(false), 300);
  };

  const updateInvoice = (property, value) => {
    let tmp = invoiceData;
    tmp[property] = value;
    setInvoiceData(tmp);
  };

  const updateInvoicePricing = (e) => {
    setDummyLoading(true);
    let { name, value } = e.target;
    let tmp = invoiceData;
    tmp[name] = parseFloat(value).toFixed(2);

    let x = parseFloat(tmp.subtotal) + parseFloat(tmp.shipping) + parseFloat(tmp.tax);
    tmp.total = parseFloat(x.toFixed(2));
    setInvoiceData(tmp);
    setTimeout(() => setDummyLoading(false), 300);
  };

  const removeInvoicePart = (itemId) => {
    setDummyLoading(true);
    let preTemp = invoiceData;
    let tmp = [];
    for (let i = 0; i < preTemp.items.length; i++) {
      const element = preTemp.items[i];
      if (element.itemId !== itemId) {
        tmp.push(element);
      }
    }
    preTemp.items = tmp;
    let subtotal = 0;
    for (let i = 0; i < preTemp.items.length; i++) {
      subtotal += parseFloat(preTemp.items[i].total);
    }
    preTemp.subtotal = subtotal;
    let x = parseFloat(preTemp.subtotal) + parseFloat(preTemp.shipping) + parseFloat(preTemp.tax);
    preTemp.total = parseFloat(x.toFixed(2));
    setInvoiceData(preTemp);
    setTimeout(() => setDummyLoading(false), 300);
  };

  const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const renderEnterInvoiceModal = () => {
    return (
      <Modal
        open={invoiceModal}
        onClose={closeInvoiceModal}
        title={`Enter AP Invoice for PO# ${po.poNumber}`}
        centered
        destroyOnClose
        width={780}
        footer={[
          <div className="flex flex-row items-center justify-end w-full gap-2 mt-6">
            <SecondaryButton label="Cancel" callback={() => closeInvoiceModal()} />
            <PrimaryButton label="Submit Invoice" callback={() => submitInvoice()} />
          </div>,
        ]}
      >
        <div className="flex flex-col items-start justify-start gap-2 mt-3">
          <div className="flex flex-col items-start justify-start w-full gap-2 mt-2">
            <div className="flex flex-col items-start justify-start w-full">
              <label htmlFor="invoiceNumber" className="block text-sm font-medium leading-6 text-gray-900">
                Invoice Number
              </label>
              <div className="w-full mt-2">
                <input
                  type="invoiceNumber"
                  name="invoiceNumber"
                  id="invoiceNumber"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:ring-inset focus:ring-transparent sm:text-sm sm:leading-6 px-2"
                  placeholder="Enter the vendor's invoice number"
                  onChange={(e) => updateInvoice("invoiceNumber", e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-col items-start justify-start w-full">
              <label htmlFor="invoiceDate" className="block text-sm font-medium leading-6 text-gray-900">
                Invoice Date
              </label>
              <DatePicker
                style={{ width: "100%", marginTop: "0.5rem" }}
                placeholder="Select invoice date"
                format={"MM/DD/YYYY"}
                onChange={(date, dateString) => updateInvoice("invoiceDate", dateString === "" ? "" : date.toJSON())}
              />
            </div>
            <div className="flex flex-col items-start justify-start w-full">
              <label htmlFor="dueDate" className="block text-sm font-medium leading-6 text-gray-900">
                Due Date
              </label>
              <DatePicker
                style={{ width: "100%", marginTop: "0.5rem" }}
                placeholder="Select invoice due date"
                format={"MM/DD/YYYY"}
                onChange={(date, dateString) => updateInvoice("dueDate", dateString === "" ? "" : date.toJSON())}
              />
            </div>
            <div className="flex flex-col items-start justify-start w-full gap-2 mb-1">
              <label htmlFor="apAccount" className="block text-sm font-medium leading-6 text-gray-900">
                AP Account
              </label>
              <Select
                defaultValue={po.poAccount && validateUUID(po.poAccount) ? po.poAccount : null}
                placeholder="Select AP Account"
                options={accounts.map((a) => {
                  return { value: a.accountId, label: `${a.accountNo} | ${a.description}` };
                })}
                className="w-full font-sans"
                controls={false}
                onChange={(val) => updateInvoice("account", val)}
                showSearch
                filterOption={filterOption}
              />
            </div>
          </div>
          <h3 className="w-full pt-3 mt-2 font-semibold border-t border-gray-300">ITEMS:</h3>
          <div className="w-full px-4 pb-3 border-b border-gray-300 max-h-[360px] overflow-y-auto">
            <table className="min-w-full overflow-y-auto divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 truncate max-w-[160px]">
                    Part No
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 truncate max-w-[200px]">
                    Description
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-[160px]">
                    Price
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-[160px]">
                    Quantity
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                    Total
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900 w-[80px]"></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {invoiceData.items.map((pt) => (
                  <tr key={uuidv4()} className="h-12">
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 truncate max-w-[160px] w-fit">{pt.partNo}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize truncate max-w-[200px]">{pt.description}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[160px]">
                      <input
                        className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed"
                        type="number"
                        id={pt.itemId}
                        defaultValue={pt.costPrice}
                        onBlur={changeInvoicePartPrice}
                      />
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[160px]">
                      <input
                        className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed"
                        type="number"
                        id={pt.itemId}
                        defaultValue={pt.quantity}
                        onBlur={changeInvoicePartQuantity}
                        max={pt.quantity}
                      />
                    </td>
                    <td className="px-3 py-4 text-sm text-right text-gray-500 whitespace-nowrap w-fit">{formatCurrency(parseFloat(pt.total))}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[80px] flex justify-end items-center">
                      <button
                        className="border border-gray-300 hover:border-red-300 duration-150 flex justify-center items-center rounded-md h-7 w-7 mt-0.5 group disabled:cursor-not-allowed"
                        onClick={() => removeInvoicePart(pt.itemId)}
                      >
                        <Xmark className="w-5 h-5 text-gray-400 duration-150 group-hover:text-red-400" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex flex-col items-end justify-start w-full gap-2 mt-2">
            <div className="w-2/3">
              <div className="relative flex items-center justify-between w-full gap-1 rounded-md">
                <label htmlFor="subtotal" className="block text-sm font-medium leading-6 text-gray-900">
                  Subtotal
                </label>
                <p className="rounded-md border-0 py-1.5 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6">
                  {formatCurrency(invoiceData.subtotal)}
                  <span className="pl-1 text-gray-500 sm:text-sm" id="subtotal-currency">
                    USD
                  </span>
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between w-2/3 gap-6">
              <label htmlFor="shipping" className="block text-sm font-medium leading-6 text-gray-900">
                Shipping/Freight/Handling
              </label>
              <div className="relative mt-2 rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input
                  type="text"
                  name="shipping"
                  id="shipping"
                  className="block w-full max-w-36 rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  aria-describedby="shipping-currency"
                  defaultValue={invoiceData.shipping}
                  onBlur={(e) => updateInvoicePricing(e)}
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <span className="text-gray-500 sm:text-sm" id="shipping-currency">
                    USD
                  </span>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between w-2/3 gap-6">
              <label htmlFor="tax" className="block text-sm font-medium leading-6 text-gray-900">
                Tax
              </label>
              <div className="relative mt-2 rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input
                  type="text"
                  name="tax"
                  id="tax"
                  className="block w-full max-w-36 rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  aria-describedby="tax-currency"
                  defaultValue={invoiceData.tax}
                  onBlur={(e) => updateInvoicePricing(e)}
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <span className="text-gray-500 sm:text-sm" id="tax-currency">
                    USD
                  </span>
                </div>
              </div>
            </div>
            <div className="w-2/3">
              <div className="relative flex items-center justify-between w-full gap-1 rounded-md">
                <label htmlFor="total" className="block text-sm font-semibold leading-6 text-gray-900">
                  Total
                </label>
                <p className="rounded-md border-0 py-1.5 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6">
                  {formatCurrency(invoiceData.total)}
                  <span className="pl-1 text-gray-500 sm:text-sm" id="total-currency">
                    USD
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const renderNote = (note) => {
    return (
      <div className="flex flex-col items-center justify-center w-full gap-2 p-6 rounded-lg bg-slate-200/75">
        <p className="w-full text-sm font-light text-slate-800">{note.note}</p>
        <div className="flex items-center justify-end w-full">
          <p className="text-sm text-slate-600">
            {note.createdByName ? note.createdByName + " on" : "Added on"} {dayjs(note.date).format("MMMM Do, YYYY [at] h:mm A")}
          </p>
        </div>
      </div>
    );
  };

  const closeNoteModal = () => {
    setNoteModal(false);
    setNote("");
  };

  const submitNote = () => {
    if (note.length > 5) {
      setLoading(true);
      AddPONote(poId, { note })
        .then((res) => {
          toast.success("Note added successfully");
          closeNoteModal();
          // reloadData();
          setTimeout(() => setLoading(false), 550);
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error adding note");
          setLoading(false);
        });
    } else {
      toast.error("Note must be at least 5 characters long");
    }
  };

  const removeService = (itemId) => {
    Modal.confirm({
      title: "Remove Service",
      icon: <Trash2 className="w-auto h-full mr-2 text-red-500" />,
      content:
        "Are you sure you want to remove this service from the purchase order? If this service was added to the job - it will be removed from it as well.",
      okText: "Remove",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        setDummyLoading(true);
        setLoading(true);
        let tmpServices = services.filter((service) => service.itemId !== itemId);
        setServices(tmpServices);
        RemovePOItem(poId, itemId).then((res) => {
          toast.success("Service removed successfully");
          setTimeout(() => {
            setLoading(false);
            setDummyLoading(false);
          }, 700);
        });
      },
      onCancel() {},
      centered: true,
      width: 550,
    });
  };

  const openAddService = () => {
    if (!editable.includes(po.poStatus)) {
      toast.error("This PO is not editable");
    } else if (po.vendorId && po.vendorId.length > 0) {
      setAddServiceModal(true);
    } else {
      toast.error("Please select a vendor first");
    }
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
        </div>
      ) : (
        <>
          <Helmet>
            <title>{po.poNumber} | HTPS ERP</title>
          </Helmet>
          <div className="flex flex-col items-center justify-start w-full h-full px-1 mt-1">
            <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
              <div className="flex items-center justify-start gap-2">
                <p className="text-xl font-bold uppercase">
                  <span className="text-sm font-semibold">PO #</span> {po.poNumber.replace("PO-", "")}
                </p>
                {po.partsFor === "job" && po.jobReference && po.jobReference.length > 0 && (
                  <button
                    className="px-4 py-1 duration-150 border border-gray-300 rounded-md hover:bg-gray-300 hover:border-black"
                    onClick={() => window.open(`/jobs/${po.jobReference}/purchaseOrders`, "_blank")}
                  >
                    <div className="flex flex-row items-center justify-center gap-2">
                      <p className="text-xs font-medium uppercase">View Job</p>
                      <OpenNewWindow className="w-3 h-3" />
                    </div>
                  </button>
                )}
              </div>
              <div className="flex flex-row items-center justify-end gap-1">
                <p className="uppercase text-xs font-medium pt-0.5">Status:</p>
                <p className="font-semibold uppercase">{po.poStatus}</p>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className={`w-full flex flex-row justify-between items-center gap-5`} key="upperForm">
              <FormProvider {...formMethods}>
                <div className="grid w-full grid-cols-2 grid-rows-5 gap-4 py-3 border-b border-gray-300">
                  <div key="vendorId" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="vendorId" className="pb-1 text-xs text-gray-600 uppercase">
                      Vendor
                    </label>
                    <Controller
                      control={control}
                      name="vendorId"
                      rules={{
                        required: "Vendor is required",
                        validate: (value) => {
                          return value.length >= 3 || "Please select a vendor";
                        },
                      }}
                      defaultValue={po.vendorId && po.vendorId.length > 10 ? po.vendorId : null}
                      render={(props) => (
                        <Select
                          placeholder="Select a vendor"
                          ref={props.field.ref}
                          name={"vendorId"}
                          onBlur={props.field.onBlur}
                          onChange={props.field.onChange}
                          options={vendors.map((vendor) => ({
                            label: `${vendor.vendorCode} | ${vendor.vendorName}`,
                            value: vendor.vendorId,
                          }))}
                          className="w-full font-sans"
                          controls={false}
                          defaultValue={po.vendorId && po.vendorId.length > 10 ? po.vendorId : null}
                          showSearch
                          filterOption={filterOption}
                          {...props.field}
                        />
                      )}
                    />
                    <ErrorMessage errors={errors} name="vendorId" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="poStatus" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="poStatus" className="pb-1 text-xs text-gray-600 uppercase">
                      Purchase Order Status
                    </label>
                    <select
                      className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-[#00000008] disabled:text-gray-400"
                      id="poStatus"
                      defaultValue={po.poStatus}
                      {...register("poStatus", {
                        required: "PO Status is required",
                        validate: (value) => value.length >= 3 || "Please select a status",
                      })}
                      disabled={true}
                    >
                      <option hidden disabled value="">
                        Select One
                      </option>
                      <option key="draft" value="draft">
                        Draft
                      </option>
                      <option key="approved" value="approved" disabled>
                        Approved
                      </option>
                      <option key="denied" value="denied" disabled>
                        Denied
                      </option>
                      <option key="submitted" value="submitted" disabled>
                        Submitted
                      </option>
                      <option key="invoiced" value="invoiced" disabled>
                        Invoiced
                      </option>
                      <option key="received" value="received" disabled>
                        Received
                      </option>
                      <option key="void" value="void">
                        VOID
                      </option>
                      <option key="backOrdered" value="backOrdered">
                        Back Ordered
                      </option>
                      <option key="returned" value="returned" disabled>
                        Returned
                      </option>
                    </select>
                    <ErrorMessage errors={errors} name="poStatus" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="poAccount" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="poAccount" className="pb-1 text-xs text-gray-600 uppercase">
                      Purchase Order Account
                    </label>
                    <Controller
                      control={control}
                      name="poAccount"
                      rules={{
                        required: "PO Account  is required",
                        validate: (value) => {
                          return value.length >= 3 || "Please select a PO account";
                        },
                      }}
                      defaultValue={po.poAccount && po.poAccount.length > 10 ? po.poAccount : null}
                      render={(props) => (
                        <Select
                          placeholder="Select an account"
                          ref={props.field.ref}
                          name={"poAccount"}
                          onBlur={props.field.onBlur}
                          onChange={props.field.onChange}
                          options={accounts.map((account) => ({
                            label: `${account.accountNo} | ${account.description}`,
                            value: account.accountId,
                          }))}
                          className="w-full font-sans"
                          controls={false}
                          defaultValue={po.poAccount && po.poAccount.length > 10 ? po.poAccount : null}
                          showSearch
                          filterOption={filterOption}
                          {...props.field}
                        />
                      )}
                    />
                    <ErrorMessage errors={errors} name="poAccount" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="jobReference" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="jobReference" className="pb-1 text-xs text-gray-600 uppercase">
                      Job Reference{" "}
                    </label>
                    <Controller
                      control={control}
                      name="jobReference"
                      rules={{ required: false }}
                      defaultValue={po.jobReference}
                      render={(props) => (
                        <Select
                          placeholder="Select a job"
                          ref={props.field.ref}
                          name={"jobReference"}
                          onBlur={props.field.onBlur}
                          onChange={props.field.onChange}
                          options={jobs.map((job) => ({
                            label: job.jobNo,
                            value: job.jobId,
                          }))}
                          className="w-full font-sans"
                          controls={false}
                          defaultValue={po.jobReference && po.jobReference.length > 10 ? po.jobReference : null}
                          showSearch
                          filterOption={filterOption}
                          {...props.field}
                        />
                      )}
                    />
                    <ErrorMessage errors={errors} name="jobReference" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="shipping" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="shipping" className="pb-1 text-xs text-gray-600 uppercase">
                      Shipping Cost
                    </label>
                    <input
                      className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-[#00000008] disabled:text-gray-400"
                      type="number"
                      id="shipping"
                      defaultValue={po.shipping || 0}
                      placeholder="Shipping Cost"
                      {...register("shipping", {
                        required: "Shipping cost is required",
                        validate: (value) => validator.isInt(value.toString()) || "Please enter a shipping cost",
                      })}
                      disabled={!editableShipping.includes(po.poStatus) || authState.user.functionCategory === "technician"}
                    />
                    <ErrorMessage errors={errors} name="shipping" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="dateSent" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="dateSent" className="pb-1 text-xs text-gray-600 uppercase">
                      Date Ordered
                    </label>
                    <Controller
                      control={formMethods.control}
                      name="dateSent"
                      rules={{
                        required: sentStatuses.includes(po.poStatus) ? "Date when PO was ordered is required" : false,
                        validate: (v) => dayjs(v).isValid() || "Date when PO was ordered is required",
                      }}
                      defaultValue={po.dateSent ? dayjs(po.dateSent) : null}
                      render={({ field, fieldState }) => (
                        <DatePicker
                          placeholder="Date when PO was ordered from the vendor"
                          status={fieldState.error ? "error" : undefined}
                          ref={field.ref}
                          name={field.name}
                          format={"MM/DD/YYYY"}
                          onBlur={field.onBlur}
                          value={field.value ? dayjs(field.value) : null}
                          onChange={(date) => {
                            field.onChange(date ? date.toJSON() : null);
                          }}
                          className="w-full"
                          disabled={!editable.includes(po.poStatus) || authState.user.functionCategory === "technician"}
                        />
                      )}
                    />
                    <ErrorMessage errors={errors} name="dateSent" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="expectedDeliveryDate" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="expectedDeliveryDate" className="pb-1 text-xs text-gray-600 uppercase">
                      Expected Delivery Date
                    </label>
                    <Controller
                      control={formMethods.control}
                      name="expectedDeliveryDate"
                      rules={{
                        required: sentStatuses.includes(po.poStatus) ? "Expected delivery date is required" : false,
                        validate: (v) => dayjs(v).isValid() || "Expected delivery data is required",
                      }}
                      defaultValue={po.expectedDeliveryDate ? dayjs(po.expectedDeliveryDate) : null}
                      render={({ field, fieldState }) => (
                        <DatePicker
                          placeholder="Expected delivery date"
                          status={fieldState.error ? "error" : undefined}
                          ref={field.ref}
                          name={field.name}
                          format={"MM/DD/YYYY"}
                          onBlur={field.onBlur}
                          value={field.value ? dayjs(field.value) : null}
                          onChange={(date) => {
                            field.onChange(date ? date.toJSON() : null);
                          }}
                          className="w-full"
                          disabled={!editableExpAndDeliveryDate.includes(po.poStatus) || authState.user.functionCategory === "technician"}
                        />
                      )}
                    />
                    <ErrorMessage errors={errors} name="expectedDeliveryDate" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="dateReceived" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="dateReceived" className="pb-1 text-xs text-gray-600 uppercase">
                      Date PO Was Received
                    </label>
                    <Controller
                      control={formMethods.control}
                      name="dateReceived"
                      rules={{
                        required: sentStatuses.includes(po.poStatus) ? "Date PO was received is required" : false,
                        validate: (v) => dayjs(v).isValid() || "Date PO was received is required",
                      }}
                      value={po.dateReceived ? dayjs(po.dateReceived) : null}
                      render={({ field, fieldState }) => (
                        <DatePicker
                          placeholder="Date PO was received"
                          status={fieldState.error ? "error" : undefined}
                          ref={field.ref}
                          name={field.name}
                          onBlur={field.onBlur}
                          format={"MM/DD/YYYY"}
                          value={field.value ? dayjs(field.value) : null}
                          onChange={(date) => {
                            field.onChange(date ? date.toJSON() : null);
                          }}
                          className="w-full"
                          disabled={authState.user.functionCategory === "technician"}
                        />
                      )}
                    />
                    <ErrorMessage errors={errors} name="dateReceived" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="talkedTo" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="talkedTo" className="pb-1 text-xs text-gray-600 uppercase">
                      Talked To / Vendor Point-of-Contact
                    </label>
                    <input
                      className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-[#00000008] disabled:text-gray-400"
                      type="text"
                      id="talkedTo"
                      defaultValue={po.talkedTo}
                      placeholder="Talked To / Vendor Point-of-Contact"
                      {...register("talkedTo", {
                        required: false,
                      })}
                      disabled={!editable.includes(po.poStatus) || authState.user.functionCategory === "technician"}
                    />
                    <ErrorMessage errors={errors} name="talkedTo" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="locationId" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="locationId" className="pb-1 text-xs text-gray-600 uppercase">
                      Location
                    </label>
                    <Controller
                      control={control}
                      name="locationId"
                      defaultValue={po.locationId && po.locationId.length > 10 ? po.locationId : null}
                      rules={{
                        required: "Location is required",
                        validate: (value) => {
                          return value.length >= 3 || "Please select a location";
                        },
                      }}
                      render={(props) => (
                        <Select
                          placeholder="Select a location"
                          ref={props.field.ref}
                          name={"locationId"}
                          onBlur={props.field.onBlur}
                          onChange={props.field.onChange}
                          options={[
                            {
                              value: "a6fe18dd-b809-4a78-85ab-e767e2b8ebcf",
                              label: "Augusta, GA",
                            },
                            {
                              value: "1f1bf36d-b744-4f69-bc9c-ca2c32a5f66f",
                              label: "North Augusta, SC",
                            },
                          ]}
                          className="w-full font-sans"
                          controls={false}
                          defaultValue={po.locationId && po.locationId.length > 10 ? po.locationId : null}
                          showSearch
                          filterOption={filterOption}
                          {...props.field}
                        />
                      )}
                    />
                    <ErrorMessage errors={errors} name="locationId" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  {customer && (
                    <div key="customer" className="flex flex-col items-start justify-start w-full col-span-2">
                      <label htmlFor="customer" className="pb-1 text-xs text-gray-600 uppercase">
                        Customer
                      </label>
                      <Controller
                        control={control}
                        name="customer"
                        defaultValue={customer && customer.customerId ? customer.customerId : null}
                        rules={{
                          required: false,
                        }}
                        disabled={true}
                        render={(props) => (
                          <Select
                            placeholder="Select a customer"
                            ref={props.field.ref}
                            name={"customer"}
                            onBlur={props.field.onBlur}
                            onChange={props.field.onChange}
                            options={[
                              {
                                value: customer.customerId,
                                label: `${customer.customerCode}${
                                  customer.company && customer.company.length > 0
                                    ? " | " + customer.company
                                    : customer.contact.firstName.length > 0
                                      ? " | " + customer.contact.firstName + " " + customer.contact.lastName
                                      : null
                                }`,
                              },
                            ]}
                            className="w-full font-sans"
                            disabled={true}
                            controls={false}
                            defaultValue={po.customer && po.customer.length > 10 ? po.customer : null}
                            showSearch
                            filterOption={filterOption}
                            {...props.field}
                          />
                        )}
                      />
                      <ErrorMessage errors={errors} name="customer" as="p" className="px-1 pt-1 text-xs text-red-500" />
                    </div>
                  )}
                </div>
              </FormProvider>
            </form>
            <div className="flex flex-col items-start justify-start flex-grow w-full gap-2 mt-3">
              <div className="flex flex-row items-center justify-between w-full pb-3 border-b border-gray-300">
                <p className="font-semibold text-gray-600 uppercase">Serialized Parts</p>
                {authState.user.functionCategory !== "technician" && <SecondaryButton label="Add a Serialized Part" callback={() => openAddSerialPart()} />}
              </div>
              {serializedItems.length === 0 ? (
                <div className="flex flex-row items-center justify-center w-full py-5">
                  <p className="text-sm font-semibold text-gray-500 uppercase">0 Serialized Parts Added</p>
                </div>
              ) : (
                <table className="min-w-full overflow-y-auto divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 w-[250px]">
                        Part No
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Description
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-[150px]">
                        Quantity
                      </th>
                      {authState.user.functionCategory !== "technician" && (
                        <>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-[150px]">
                            Cost
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-[150px]">
                            Ext. Price
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900 w-[150px]">
                            Total
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-[80px]"></th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {serializedItems.map((pt) => (
                      <tr key={uuidv4()} className="h-12">
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 w-[250px]">{pt.partNo}</td>
                        <td className="px-3 py-4 text-sm text-gray-500 capitalize truncate whitespace-nowrap">
                          {pt.description.slice(0, 50)}
                          {pt.description.length > 50 && "..."}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[150px] text-left">{pt.quantity}</td>
                        {authState.user.functionCategory !== "technician" && (
                          <>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[150px] text-left">{formatCurrency(pt.costPrice)}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[150px] text-left">{formatCurrency(pt.price)}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[150px] text-right">{formatCurrency(pt.total)}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[100px] flex justify-end items-center gap-2">
                              <button
                                className="border border-gray-300 hover:border-blue-300 duration-150 flex justify-center items-center rounded-md h-7 w-7 mt-0.5 group disabled:cursor-not-allowed disabled:bg-[#00000008] disabled:text-gray-400"
                                onClick={() => editSerialOpen(pt.itemId)}
                                disabled={!editable.includes(po.poStatus)}
                              >
                                <Edit2 size={14} className="text-gray-400 duration-150 group-hover:text-blue-400" />
                              </button>
                              <button
                                className="border border-gray-300 hover:border-red-300 duration-150 flex justify-center items-center rounded-md h-7 w-7 mt-0.5 group disabled:cursor-not-allowed disabled:bg-[#00000008] disabled:text-gray-400"
                                onClick={() => removeSerialPart(pt.itemId)}
                                disabled={!editable.includes(po.poStatus)}
                              >
                                <Xmark className="w-5 h-5 text-gray-400 duration-150 group-hover:text-red-400" />
                              </button>
                            </td>{" "}
                          </>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              <div className="flex flex-row items-center justify-between w-full py-3 border-gray-300 border-y">
                <p className="font-semibold text-gray-600 uppercase">Non-Serialized Parts</p>
                {authState.user.functionCategory !== "technician" && (
                  <SecondaryButton label="Add a  Non-Serialized Part" callback={() => openAddNonSerialPart()} />
                )}
              </div>
              {nonSerializedItems.length === 0 ? (
                <div className="flex flex-row items-center justify-center w-full py-5">
                  <p className="text-sm font-semibold text-gray-500 uppercase">0 Non-Serialized Parts Added</p>
                </div>
              ) : (
                <table className="min-w-full overflow-y-auto divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 w-[250px]">
                        Part No
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Description
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-[150px]">
                        Quantity
                      </th>
                      {authState.user.functionCategory !== "technician" && (
                        <>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-[150px]">
                            Cost
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-[150px]">
                            Ext. Price
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900 w-[150px]">
                            Total
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-[80px]"></th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {nonSerializedItems.map((pt) => (
                      <tr key={uuidv4()} className="h-12">
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 w-[250px]">{pt.partNo}</td>
                        <td className="px-3 py-4 text-sm text-gray-500 capitalize truncate whitespace-nowrap">{pt.description}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[150px] text-left">{pt.quantity}</td>
                        {authState.user.functionCategory !== "technician" && (
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[150px] text-left">{formatCurrency(pt.costPrice)}</td>
                        )}
                        {authState.user.functionCategory !== "technician" && (
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[150px] text-left">{formatCurrency(pt.price)}</td>
                        )}
                        {authState.user.functionCategory !== "technician" && (
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[150px] text-right">{formatCurrency(pt.total)}</td>
                        )}
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[100px] flex justify-end items-center gap-2">
                          <button
                            className="border border-gray-300 hover:border-blue-300 duration-150 flex justify-center items-center rounded-md h-7 w-7 mt-0.5 group disabled:cursor-not-allowed disabled:bg-[#00000008] disabled:text-gray-400"
                            onClick={() => openEditNonSerial(pt.itemId)}
                            disabled={!editable.includes(po.poStatus)}
                          >
                            <Edit2 size={14} className="text-gray-400 duration-150 group-hover:text-blue-400" />
                          </button>
                          <button
                            className="border border-gray-300 hover:border-red-300 duration-150 flex justify-center items-center rounded-md h-7 w-7 mt-0.5 group disabled:cursor-not-allowed disabled:bg-[#00000008] disabled:text-gray-400"
                            onClick={() => removeNonSerialPart(pt.itemId)}
                            disabled={!editable.includes(po.poStatus)}
                          >
                            <Xmark className="w-5 h-5 text-gray-400 duration-150 group-hover:text-red-400" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              <div className="flex flex-row items-center justify-between w-full py-3 border-gray-300 border-y">
                <p className="font-semibold text-gray-600 uppercase">Services</p>
                {authState.user.functionCategory !== "technician" && <SecondaryButton label="Add a Service" callback={() => openAddService()} />}
              </div>
              {services.length === 0 ? (
                <div className="flex flex-row items-center justify-center w-full py-5">
                  <p className="text-sm font-semibold text-gray-500 uppercase">0 Services Added</p>
                </div>
              ) : (
                <table className="min-w-full overflow-y-auto divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 w-[250px]">
                        Service ID
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Description
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-[150px]">
                        Quantity
                      </th>
                      {authState.user.functionCategory !== "technician" && (
                        <>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-[150px]">
                            Cost
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-[150px]">
                            Ext. Price
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900 w-[150px]">
                            Total
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-[80px]"></th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {services.map((pt) => (
                      <tr key={uuidv4()} className="h-12">
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 w-[250px]">{pt.partNo}</td>
                        <td className="px-3 py-4 text-sm text-gray-500 capitalize truncate whitespace-nowrap">{pt.description}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[150px] text-left">{pt.quantity}</td>
                        {authState.user.functionCategory !== "technician" && (
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[150px] text-left">{formatCurrency(pt.costPrice)}</td>
                        )}
                        {authState.user.functionCategory !== "technician" && (
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[150px] text-left">{formatCurrency(pt.price)}</td>
                        )}
                        {authState.user.functionCategory !== "technician" && (
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[150px] text-right">{formatCurrency(pt.total)}</td>
                        )}

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[100px] flex justify-end items-center gap-2">
                          <button
                            className="border border-gray-300 hover:border-blue-300 duration-150 flex justify-center items-center rounded-md h-7 w-7 mt-0.5 group disabled:cursor-not-allowed disabled:bg-[#00000008] disabled:text-gray-400"
                            onClick={() => openEditService(pt.itemId)}
                            disabled={!editable.includes(po.poStatus)}
                          >
                            <Edit2 size={14} className="text-gray-400 duration-150 group-hover:text-blue-400" />
                          </button>
                          <button
                            className="border border-gray-300 hover:border-red-300 duration-150 flex justify-center items-center rounded-md h-7 w-7 mt-0.5 group disabled:cursor-not-allowed disabled:bg-[#00000008] disabled:text-gray-400"
                            onClick={() => removeService(pt.itemId)}
                            disabled={!editable.includes(po.poStatus)}
                          >
                            <Xmark className="w-5 h-5 text-gray-400 duration-150 group-hover:text-red-400" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              <div className="flex flex-row items-center justify-between w-full py-3 border-gray-300 border-y">
                <p className="font-semibold text-gray-600 uppercase">Purchase Order Notes</p>
                <SecondaryButton label="Add a Note" callback={() => setNoteModal(true)} />
              </div>
              {po.notes.length > 0 ? po.notes.map((note) => renderNote(note)) : <p className="w-full text-sm text-center text-gray-400">No notes added</p>}
            </div>
            {authState.user.functionCategory !== "technician" && (
              <div className="w-full flex flex-row justify-end items-center gap-2 py-5 border-t border-gray-300 px-0.5 mt-2">
                <SecondaryButton label="Print Preview" callback={() => printPOPreview()} />
                {po.jobReference && po.jobReference.length > 0 && (
                  <SecondaryButton label="View Job" callback={() => window.open(`/jobs/${po.jobReference}`, "_blank").focus()} />
                )}
                {renderPOActions()}
              </div>
            )}
          </div>
          <Modal
            open={noteModal}
            title="Add a Note to Purchase Order"
            centered
            width={750}
            destroyOnClose
            onCancel={() => closeNoteModal()}
            onOk={() => submitNote()}
          >
            <p className="mb-2 font-medium text-slate-600">Enter your note</p>
            <Input.TextArea placeholder="Enter your note here" onChange={(e) => setNote(e.target.value)} rows={5} autoSize={{ maxRows: 5, minRows: 5 }} />
          </Modal>
          {renderAddSerial()}
          {renderAddNonSerial()}
          {renderEditSerial()}
          {renderEditNonSerial()}
          {renderVoidApproveDenyModal()}
          {renderSubmitPOModal()}
          {renderReceivedPartsModal()}
          {renderEnterInvoiceModal()}
          {renderAddService()}
          {renderEditService()}
        </>
      )}
    </div>
  );
};

export default OpenPO;
