import { useEffect, useState, Fragment, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Dialog, Transition, Switch } from "@headlessui/react";
import { GetOneJob, GetEmployees, GetRates, UpdateJobLabor, AddLaborToAJob } from "../../actions/jobs";
import toast from "react-hot-toast";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { formatCurrency } from "../../components/tools";
import * as _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { Xmark, EditPencil, Trash } from "iconoir-react";
import { DatePicker, Drawer, InputNumber, Modal, Select, TimePicker } from "antd";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/en_US";
import { Helmet } from "react-helmet-async";

const JobLabor = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  let [job, setJob] = useState({});
  // eslint-disable-next-line
  let [location, setLocation] = useState({});
  // eslint-disable-next-line
  let [dummyLoading, setDummyLoading] = useState(false);
  let [employees, setEmployees] = useState([]);
  let [rates, setRates] = useState([]);
  let [editLaborModal, setEditLaborModal] = useState(false);
  let [editLabor, setEditLabor] = useState({
    laborDescription: "",
    billableTime: 0,
    rate: "",
    billedAmount: 0,
    quoted: 0,
    perHour: 0,
    quotedPrice: 0,
    laborId: "",
    status: "",
    log: [],
  });
  let [workLog, setWorkLog] = useState({
    tech: authState.user.userId,
    laborDate: "",
    clockIn: "",
    clockOut: "",
    description: "",
    jobCompleted: false,
    logId: "",
  });
  let [logLaborDrawer, setLogLaborDrawer] = useState(false);
  let [addLaborModal, setAddLaborModal] = useState(false);
  let [addLabor, setAddLabor] = useState({
    rate: "",
    laborDescription: "",
    quoted: "",
  });

  const { jobId } = useParams();
  const navigate = useNavigate();

  const laborStatusRef = useRef(null);

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetOneJob(jobId)
        .then((res) => {
          setJob(res.data.job);
          setLocation(res.data.location);
          GetRates()
            .then((res) => {
              setRates(res.data);
              GetEmployees()
                .then((res) => {
                  setEmployees(res.data);
                  setTimeout(() => setLoading(false), 700);
                })
                .catch((err) => {
                  toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
                  setTimeout(() => {
                    navigate("/jobs");
                  }, 3000);
                });
            })
            .catch((err) => {
              toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
              setTimeout(() => {
                navigate("/jobs");
              }, 3000);
            });
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
          setTimeout(() => {
            navigate("/jobs");
          }, 3000);
        });
    }
    return () => {
      inView = false;
    };
    // eslint-disable-next-line
  }, []);

  const tabs = [
    { name: "Info", href: "#", current: false },
    { name: "Parts", href: "parts", current: false },
    { name: "Labor", href: "labor", current: true },
    { name: "Parking", href: "parking", current: false },
    { name: "Loadbank", href: "loadbank", current: false },
    { name: "Freight", href: "freight", current: false },
    { name: "Misc", href: "misc", current: false },
    { name: "POs", href: "purchaseOrders", current: false },
    {
      name: "ITs",
      href: "inventoryTransmittals",
      current: false,
    },
    { name: "Equip.", href: "equipment", current: false },
    { name: "Tests", href: "tests", current: false },
    { name: "Warranty", href: "warranty", current: false },
    { name: "Payments", href: "payments", current: false },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const renderLaborStatus = (status) => {
    switch (status) {
      case "quoted":
        return <p className="px-2 py-1 text-xs font-semibold text-center border rounded-md text-amber-500 border-amber-500 bg-amber-50">Quoted</p>;
      case "completed":
        return <p className="px-2 py-1 text-xs font-semibold text-center text-green-700 border border-green-700 rounded-md bg-green-50">Completed</p>;
      case "skipped":
        return <p className="px-2 py-1 text-xs font-semibold text-center text-red-700 border border-red-700 rounded-md bg-red-50">Skipped</p>;
      case "incomplete":
        return <p className="px-2 py-1 text-xs font-semibold text-center text-red-700 border border-red-700 rounded-md bg-red-50">Incomplete</p>;
      case "void":
        return <p className="px-2 py-1 text-xs font-semibold text-center text-red-700 border border-red-700 rounded-md bg-red-50">VOID</p>;
      case "additionalLabor":
        return <p className="px-2 py-1 text-xs font-semibold text-center border rounded-md text-sky-500 border-sky-500 bg-sky-50">Additional Labor</p>;
      case "inProgress":
        return <p className="px-2 py-1 text-xs font-semibold text-center border rounded-md text-sky-500 border-sky-500 bg-sky-50">In-Progress</p>;
      default:
        return <p className="px-2 py-1 text-xs font-semibold text-center text-gray-500 border border-gray-500 rounded-md bg-gray-50">Unknown</p>;
    }
  };

  const renderLabor = (lbr, index) => {
    let rate = "Unknown";
    let rateObj = rates.find((r) => r.rateId === lbr.rate);
    if (rateObj) {
      rate = rateObj.laborCode;
    }
    let techs = [];
    lbr.log.forEach((lbrlog) => {
      let techId = lbrlog.tech;
      let tech = employees.find((emp) => emp.userId === techId);
      if (tech) {
        let fullName = tech.firstName + " " + tech.lastName;
        if (!techs.includes(fullName)) {
          techs.push(fullName);
        }
      }
    });
    let techNames = techs.join(", ");

    return (
      <div
        className={`grid w-full ${
          authState.user.functionCategory !== "technician" ? "grid-cols-11" : "grid-cols-3 md:grid-cols-9"
        } gap-4 py-3 border-b border-gray-300`}
        key={uuidv4()}
      >
        <div className="w-full flex justify-start items-center px-0.5">{renderLaborStatus(lbr.status)}</div>
        <p className="hidden md:inline-flex">{techs.length > 0 ? techNames : "No time logged"}</p>
        <p className="hidden md:inline-flex">{lbr.log && lbr.log.length > 0 ? dayjs(lbr.log[0].laborDate).format("MM/DD/YYYY") : "Not Provided"}</p>
        <p className="text-left md:col-span-3">{lbr.laborDescription}</p>
        <p className="hidden text-center md:inline-flex">{rate}</p>
        <p className="hidden text-right md:inline-flex">{renderActualTime(lbr)}</p>
        {authState.user.functionCategory !== "technician" && (
          <>
            <p className="text-right">{formatCurrency(lbr.quotedPrice)}</p>
            <p className="text-right">{formatCurrency(lbr.billedAmount)}</p>
          </>
        )}
        {job.jobStatus === "open" && (
          <div className="flex flex-row items-center justify-center">
            <SecondaryButton label="Edit" callback={() => openEditLabor(lbr)} />
          </div>
        )}
      </div>
    );
  };

  const renderActualTime = (lbr) => {
    let total = 0;
    lbr.log.forEach((log) => {
      total += parseFloat(renderLaborHours(log));
    });
    return total.toFixed(2) + " hrs";
  };

  const openEditLabor = (lbr) => {
    setEditLabor({
      laborDescription: lbr.laborDescription,
      billableTime: lbr.billableTime,
      rate: lbr.rate,
      billedAmount: lbr.billedAmount,
      quoted: lbr.quoted,
      perHour: lbr.perHour,
      quotedPrice: lbr.quotedPrice,
      laborId: lbr.laborId,
      status: lbr.status,
      log: lbr.log,
    });
    setTimeout(() => setEditLaborModal(true), 700);
  };

  const closeEditLaborModal = () => {
    setEditLaborModal(false);
    setEditLabor({
      laborDescription: "",
      billableTime: 0,
      rate: "",
      billedAmount: 0,
      quoted: 0,
      perHour: 0,
      quotedPrice: 0,
      laborId: "",
      status: "",
      log: [],
    });
    setWorkLog({
      tech: "",
      laborDate: "",
      clockIn: "",
      clockOut: "",
      description: "",
      logId: "",
      jobCompleted: false,
    });
  };

  const editLaborChange = (e) => {
    let { name, value } = e.target;
    let tmp = editLabor;
    tmp[name] = value;
    setEditLabor(tmp);
  };

  const updateWorkLogEmployee = (e) => {
    setDummyLoading(true);
    let tmp = workLog;
    tmp.tech = e;
    setWorkLog(tmp);
    setTimeout(() => setDummyLoading(false), 300);
  };

  const editWorkLogChange = (e) => {
    setDummyLoading(true);
    let { name, value } = e.target;
    let tmp = workLog;
    tmp[name] = value;
    setWorkLog(tmp);
    setTimeout(() => setDummyLoading(false), 300);
  };

  const updateJobComplete = (e) => {
    setDummyLoading(true);
    let tmp = workLog;
    tmp.jobCompleted = e;
    setWorkLog(tmp);
    setTimeout(() => setDummyLoading(false), 300);
  };

  const workLogDateChange = (date, dateString) => {
    setDummyLoading(true);
    let tmp = workLog;
    tmp.laborDate = date.toJSON();
    setWorkLog(tmp);
    setTimeout(() => setDummyLoading(false), 300);
  };

  const workLogClockInChange = (time, timeString) => {
    setDummyLoading(true);
    let tmp = workLog;
    tmp.clockIn = time.toJSON();
    setWorkLog(tmp);
    setTimeout(() => setDummyLoading(false), 300);
  };

  const workLogClockOutChange = (time, timeString) => {
    setDummyLoading(true);
    let tmp = workLog;
    tmp.clockOut = time.toJSON();
    setWorkLog(tmp);
    setTimeout(() => setDummyLoading(false), 300);
  };

  const getWorkedHours = (clockIn, clockOut) => {
    if (clockIn !== "" && clockOut !== "") {
      let start = dayjs(clockIn);
      let end = dayjs(clockOut);
      let difference = end.diff(start, "minute");
      let workedHr = Math.floor(difference / 60);
      let workedMin = difference - workedHr * 60;
      if (workedMin < 0) {
        workedHr -= 1;
        workedMin += 60;
      }
      if (workedHr < 10) {
        workedHr = "0" + workedHr;
      }
      if (workedMin < 10) {
        workedMin = "0" + workedMin;
      }
      return workedHr + ":" + workedMin;
    } else {
      return "0:00";
    }
  };

  const submitLogLabor = () => {
    setDummyLoading(true);
    if (workLog.logId === "") {
      let tmp = editLabor;
      let start = dayjs(workLog.clockIn);
      let end = dayjs(workLog.clockOut).second(0).millisecond(0);
      let date = dayjs(workLog.laborDate).hour(start.hour()).minute(start.minute()).second(0).millisecond(0);
      end = date.hour(end.hour()).minute(end.minute());
      tmp.log.push({
        tech: workLog.tech,
        laborDate: date.toJSON(),
        clockIn: date.toJSON(),
        clockOut: end.toJSON(),
        description: workLog.description,
        logId: uuidv4(),
        jobCompleted: workLog.jobCompleted,
      });
      if (tmp.log.length > 0) {
        tmp.status = "inProgress";
        if (laborStatusRef.current) {
          laborStatusRef.current.value = "inProgress";
        }
      }
      if (workLog.jobCompleted) {
        tmp.status = "completed";
        if (laborStatusRef.current) {
          laborStatusRef.current.value = "completed";
        }
      }
      setEditLabor(tmp);
    } else {
      let index = editLabor.log.findIndex((log) => log.logId === workLog.logId);
      let tmp = editLabor;
      let start = dayjs(workLog.clockIn);
      let end = dayjs(workLog.clockOut);
      let date = dayjs(workLog.laborDate).hour(start.hour()).minute(start.minute()).second(0).millisecond(0);
      end = date.hour(end.hour()).minute(end.minute()).second(0).millisecond(0);
      tmp.log[index] = {
        tech: workLog.tech,
        laborDate: date.toJSON(),
        clockIn: date.toJSON(),
        clockOut: end.toJSON(),
        description: workLog.description,
        logId: workLog.logId,
        jobCompleted: workLog.jobCompleted,
      };
      if (tmp.log.length > 0) {
        tmp.status = "inProgress";
        if (laborStatusRef.current) {
          laborStatusRef.current.value = "inProgress";
        }
      }
      if (workLog.jobCompleted) {
        tmp.status = "completed";
        if (laborStatusRef.current) {
          laborStatusRef.current.value = "completed";
        }
      }
      setEditLabor(tmp);
    }
    setTimeout(() => setDummyLoading(false), 300);
    closeLogLabor();
  };

  const renderLaborLine = (log, index) => {
    return (
      <div className="grid items-center w-full grid-cols-8 px-1">
        <div className="col-span-1">
          <p className="text-xs font-semibold text-gray-900">#{index + 1}</p>
        </div>
        <div className="col-span-1">
          <p className="text-xs font-semibold text-gray-900">
            {employees.find((e) => e.userId === log.tech)?.firstName || ""} {employees.find((e) => e.userId === log.tech)?.lastName || ""}
          </p>
        </div>
        <div className="col-span-4">
          <p className="w-full text-xs font-semibold text-gray-900 truncate">{log.description}</p>
        </div>
        <div className="col-span-1">
          <p className="text-xs font-semibold text-gray-900">{renderLaborHours(log)} hrs</p>
        </div>
        <div className="flex flex-row items-center justify-end col-span-1 gap-1">
          <button className="flex items-center justify-center w-8 h-8 text-gray-500 border border-gray-500 rounded-md" onClick={() => editLaborLog(log)}>
            <EditPencil className="w-5 h-5" />
          </button>
          <button className="flex items-center justify-center w-8 h-8 text-red-500 border border-red-500 rounded-md" onClick={() => removeLaborLog(log.logId)}>
            <Trash className="w-5 h-5" />
          </button>
        </div>
      </div>
    );
  };

  const renderLaborHours = (log) => {
    if (log.clockIn !== "" && log.clockOut !== "") {
      let start = dayjs(log.clockIn);
      let end = dayjs(log.clockOut);
      let difference = end.diff(start, "minute");
      let hours = Math.floor(difference / 60);
      let mins = difference - hours * 60;
      if (mins >= 53) {
        hours += 1;
        mins = "00";
      } else if (mins <= 7) {
        mins = "00";
      } else if (mins >= 8 && mins <= 22) {
        mins = "25";
      } else if (mins >= 23 && mins <= 37) {
        mins = "50";
      } else if (mins >= 38 && mins <= 52) {
        mins = "75";
      }
      return hours + "." + mins;
    } else {
      return "0.00";
    }
  };

  const renderActualHours = () => {
    let total = 0;
    let totalArr = [];
    for (let i = 0; i < editLabor.log.length; i++) {
      let log = editLabor.log[i];
      total += parseFloat(getWorkedHours(log.clockIn, log.clockOut).split(":")[0]) * 60;
      total += parseFloat(getWorkedHours(log.clockIn, log.clockOut).split(":")[1]);
      let x = renderLaborHours(log);
      totalArr.push(x);
    }
    let x = 0;
    for (let i = 0; i < totalArr.length; i++) {
      let element = totalArr[i];
      x += parseFloat(element);
    }
    x = x.toFixed(2);
    return x;
  };

  const renderActualPrice = () => {
    let value = renderActualHours();
    let hours = parseFloat(value);
    return formatCurrency(value * editLabor.perHour);
  };

  const renderBillableHours = () => {
    let total = 0;
    if (editLabor.log.length > 0) {
      editLabor.log.forEach((log) => {
        total += parseFloat(getWorkedHours(log.clockIn, log.clockOut).split(":")[0]) * 60;
        total += parseFloat(getWorkedHours(log.clockIn, log.clockOut).split(":")[1]);
      });
    }
    let hours = Math.floor(total / 60);
    if (editLabor.quoted > hours) {
      return parseFloat(editLabor.quoted);
    } else {
      let minutes = total % 60;
      minutes = (100 * minutes) / 60;
      return hours + "." + minutes.toFixed(0);
    }
  };

  const renderBillablePrice = () => {
    let total = 0;
    for (let i = 0; i < editLabor.log.length; i++) {
      total += parseFloat(renderLaborHours(editLabor.log[i]));
    }
    if (editLabor.quoted > total) {
      return parseFloat((editLabor.quoted * editLabor.perHour).toFixed(2));
    } else {
      return parseFloat(total * editLabor.perHour);
    }
  };

  const removeLaborLog = (logId) => {
    setDummyLoading(true);
    let tmp = editLabor;
    tmp.log = tmp.log.filter((log) => log.logId !== logId);
    if (tmp.log.length === 0) {
      tmp.status = "incomplete";
      if (laborStatusRef.current) {
        laborStatusRef.current.value = "incomplete";
      }
    }
    setEditLabor(tmp);
    setTimeout(() => setDummyLoading(false), 300);
  };
  const editLaborLog = (log) => {
    let x = {
      tech: log.tech,
      laborDate: log.laborDate,
      clockIn: log.clockIn,
      clockOut: log.clockOut,
      description: log.description,
      logId: log.logId,
      jobCompleted: log.jobCompleted,
    };
    setWorkLog(x);
    setTimeout(() => setLogLaborDrawer(true), 700);
  };

  const renderEditLaborModal = () => {
    return (
      <Modal open={editLaborModal} title="Edit Labor" centered onCancel={() => closeEditLaborModal()} okText="Save Changes" onOk={() => saveEditLabor()}>
        <div className="flex flex-col items-center justify-start w-full gap-2 mt-4">
          {authState.user.functionCategory !== "technician" && (
            <div className="w-full">
              <label htmlFor="rate" className="block text-sm font-medium leading-6 text-gray-900">
                Rate
              </label>
              <select
                id="rate"
                name="rate"
                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                value={editLabor.rate}
                onChange={(e) => editLaborChange(e)}
              >
                <option value={""} key={uuidv4()} selected={editLabor.rate === ""} disabled>
                  Select One
                </option>
                {rates.map((rate) => (
                  <option value={rate.rateId} key={uuidv4()}>
                    {rate.laborCode}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="w-full">
            <label htmlFor="quoted" className="block text-sm font-medium leading-6 text-gray-900">
              Quoted Number of Hours
            </label>
            <div className="mt-2">
              <input
                type="number"
                name="quoted"
                id="quoted"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                placeholder="Quoted number of hours"
                defaultValue={editLabor.quoted}
                // ref={quantityRef}
                onChange={(e) => editLaborChange(e)}
              />
            </div>
          </div>
          <div className="w-full">
            <label htmlFor="quoted" className="block text-sm font-medium leading-6 text-gray-900">
              Charge-Out Price
            </label>
            <div className="mt-2">
              <InputNumber
                name="quotedPrice"
                onChange={(e) => setLaborPriceOverride(e)}
                defaultValue={editLabor.quotedPrice ? editLabor.quotedPrice : 0}
                className="w-full font-sans py-0.5 px-1.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                controls={false}
                placeholder="Quoted number of hours"
              />
            </div>
          </div>
          <div className="w-full">
            <label htmlFor="status" className="block text-sm font-medium leading-6 text-gray-900">
              Status
            </label>
            <select
              id="status"
              name="status"
              className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              defaultValue={editLabor.status === "" ? "" : editLabor.status}
              onChange={(e) => editLaborChange(e)}
              ref={laborStatusRef}
            >
              <option value={""} key={uuidv4()} selected={editLabor.status === ""} disabled>
                Select One
              </option>
              <option value={"quoted"} key={uuidv4()}>
                Quoted
              </option>
              <option value={"incomplete"} key={uuidv4()}>
                Incomplete
              </option>
              <option value={"inProgress"} key={uuidv4()}>
                In-Progress
              </option>
              <option value={"completed"} key={uuidv4()}>
                Completed
              </option>
              <option value={"skipped"} key={uuidv4()}>
                Skipped
              </option>
              <option value={"additionalLabor"} key={uuidv4()}>
                Additional Labor
              </option>
              <option value={"void"} key={uuidv4()}>
                VOID
              </option>
            </select>
          </div>
          <div className="w-full">
            <label htmlFor="laborDescription" className="block text-sm font-medium leading-6 text-gray-900">
              Labor Description
            </label>
            <textarea
              id="laborDescription"
              name="laborDescription"
              rows={3}
              className="block w-full mt-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              defaultValue={editLabor.laborDescription}
              onChange={(e) => editLaborChange(e)}
            />
          </div>
          <div className="flex flex-row items-center justify-between w-full pt-3 mt-2 border-t border-gray-300">
            <h3 className="text-sm font-semibold uppercase">Logged Time</h3>
            <SecondaryButton label="Log Time" callback={() => setLogLaborDrawer(true)} />
          </div>
          <div
            className={`w-full flex flex-col ${
              editLabor.log.length === 0 ? "justify-center" : "justify-start"
            } items-center gap-2 h-[150px] overflow-auto mt-2`}
          >
            {editLabor.log.length === 0 ? (
              <p className="font-bold uppercase text-gray-400/50">No time was logged</p>
            ) : (
              editLabor.log.map((log, logIndex) => renderLaborLine(log, logIndex))
            )}
          </div>
          {authState.user.functionCategory !== "technician" && (
            <>
              <div className="flex flex-row items-center justify-start w-full pt-3 mt-2 border-t border-gray-300">
                <h3 className="text-sm font-semibold uppercase">Summary</h3>
              </div>
              <div className="grid w-full grid-cols-3 gap-6">
                <div className="flex flex-col items-start justify-start w-full col-span-1 gap-1">
                  <p className="text-xs font-semibold uppercase">Quoted</p>
                  <div className="flex flex-row items-center justify-between w-full">
                    <p className="text-sm">Hours:</p>
                    <p className="text-sm font-semibold">{editLabor.quoted}</p>
                  </div>
                  <div className="flex flex-row items-center justify-between w-full">
                    <p className="text-sm">Rate:</p>
                    <p className="text-sm font-semibold">{formatCurrency(editLabor.perHour)}</p>
                  </div>
                  <div className="flex flex-row items-center justify-between w-full">
                    <p className="text-sm">Price:</p>
                    <p className="text-sm font-semibold">{formatCurrency(editLabor.quotedPrice)}</p>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start w-full col-span-1 gap-1">
                  <p className="text-xs font-semibold uppercase">Actual</p>
                  <div className="flex flex-row items-center justify-between w-full">
                    <p className="text-sm">Hours:</p>
                    <p className="text-sm font-semibold">{renderActualHours()} hrs</p>
                  </div>
                  <div className="flex flex-row items-center justify-between w-full">
                    <p className="text-sm">Rate:</p>
                    <p className="text-sm font-semibold">{formatCurrency(editLabor.perHour)}</p>
                  </div>
                  <div className="flex flex-row items-center justify-between w-full">
                    <p className="text-sm">Price:</p>
                    <p className="text-sm font-semibold">{renderActualPrice()}</p>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start w-full col-span-1 gap-1">
                  <p className="text-xs font-semibold uppercase">Billable</p>
                  <div className="flex flex-row items-center justify-between w-full">
                    <p className="text-sm">Hours:</p>
                    <p className="text-sm font-semibold">{renderBillableHours()} hrs</p>
                  </div>
                  <div className="flex flex-row items-center justify-between w-full">
                    <p className="text-sm">Rate:</p>
                    <p className="text-sm font-semibold">{formatCurrency(editLabor.perHour)}</p>
                  </div>
                  <div className="flex flex-row items-center justify-between w-full">
                    <p className="text-sm">Price:</p>
                    <p className="text-sm font-semibold">{formatCurrency(renderBillablePrice())}</p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
    );
  };

  const closeLogLabor = () => {
    setLogLaborDrawer(false);
    setWorkLog({
      tech: "",
      laborDate: "",
      clockIn: "",
      clockOut: "",
      description: "",
      logId: "",
      jobCompleted: false,
    });
  };

  const setLaborPriceOverride = (e) => {
    let tmp = editLabor;
    tmp.quotedPrice = e;
    setEditLabor(tmp);
  };

  const saveEditLabor = () => {
    let tmp = editLabor;
    tmp.billableTime = renderBillableHours();
    tmp.billedAmount = renderBillablePrice();
    setLoading(true);
    UpdateJobLabor(jobId, tmp.laborId, tmp)
      .then((res) => {
        toast.success("Labor entry updated successfully");
        closeEditLaborModal();
        GetOneJob(jobId)
          .then((res) => {
            setJob(res.data.job);
            setLocation(res.data.location);
            GetRates()
              .then((res) => {
                setRates(res.data);
                GetEmployees()
                  .then((res) => {
                    setEmployees(res.data);
                    setTimeout(() => setLoading(false), 700);
                  })
                  .catch((err) => {
                    toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
                    setTimeout(() => {
                      navigate("/jobs");
                    }, 3000);
                  });
              })
              .catch((err) => {
                toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
                setTimeout(() => {
                  navigate("/jobs");
                }, 3000);
              });
          })
          .catch((err) => {
            toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
            setTimeout(() => {
              navigate("/jobs");
            }, 3000);
          });
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error updating labor entry");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
  };

  const getLogWorkError = () => {
    let error = "";
    let hasError = false;
    if (workLog.tech === "" && workLog.description === "" && workLog.laborDate === "" && workLog.clockIn === "" && workLog.clockOut === "") {
      error = "Please fill out all fields";
      hasError = true;
    } else if (workLog.laborDate === "" || workLog.clockIn === "" || workLog.clockOut === "") {
      error = "Please enter the date & time you worked";
      hasError = true;
    } else if (workLog.clockIn === "" || workLog.clockOut === "") {
      error = "Please provide your clocked in and out times";
      hasError = true;
    } else if (workLog.clockOut === "") {
      error = "Please enter your clock-out time";
      hasError = true;
    } else if (workLog.clockIn === "") {
      error = "Please enter your clock-in time";
      hasError = true;
    } else if (workLog.tech === "") {
      error = "Please select a technician";
      hasError = true;
    } else if (workLog.description === "") {
      error = "Please enter a description of the work performed";
      hasError = true;
    } else if (workLog.laborDate === "") {
      error = "Please enter the date you worked";
      hasError = true;
    }

    if (!hasError) {
      let start = dayjs(workLog.clockIn);
      let end = dayjs(workLog.clockOut);
      if (start.isAfter(end)) {
        error = "Clock-in time is after clock-out time";
        hasError = true;
      } else if (start.isSame(end, "minute")) {
        error = "Clock-in time is the same as clock-out time";
        hasError = true;
      }
    }

    if (hasError) {
      return <p className="w-full pt-4 mt-4 text-sm font-medium text-red-500 border-t border-gray-300">ERROR: {error}</p>;
    } else {
      return false;
    }
  };

  const renderLogLaborDrawer = () => {
    return (
      <Drawer open={logLaborDrawer} placement="right" onClose={() => closeLogLabor()} maskClosable={false} title="Log Labor Time" destroyOnClose>
        <div className="relative flex flex-col items-center justify-between flex-1 gap-2">
          <div className="flex flex-col items-start justify-start w-full gap-2">
            <div className="w-full">
              <label htmlFor="tech" className="block text-sm font-medium leading-6 text-gray-900">
                Technician
              </label>
              <Select
                defaultValue={workLog.tech ? workLog.tech : authState.user.userId}
                onChange={(e) => updateWorkLogEmployee(e)}
                options={employees.map((emp) => ({
                  value: emp.userId,
                  label: `${emp.employeeCode} | ${emp.firstName} ${emp.lastName}`,
                }))}
                placeholder="Select a technician"
                className="w-full"
              />
            </div>
            <div className="w-full">
              <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                Labor Description
              </label>
              <div className="mt-2">
                <textarea
                  rows={4}
                  name="description"
                  id="description"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 resize-none"
                  defaultValue={workLog.description}
                  onChange={(e) => editWorkLogChange(e)}
                />
              </div>
            </div>
            <div className="flex flex-row items-center justify-between w-full mt-2">
              <label htmlFor="laborDate" className="block text-sm font-medium leading-6 text-gray-900">
                Labor Date
              </label>
              <DatePicker
                style={{ width: "45%" }}
                placeholder="Select Labor Date"
                format="MM/DD/YYYY"
                defaultValue={workLog.laborDate !== "" ? dayjs(workLog.laborDate) : null}
                onChange={workLogDateChange}
                locale={locale}
              />
            </div>
            <div className="flex flex-row items-center justify-between w-full mt-2">
              <label htmlFor="clockIn" className="block text-sm font-medium leading-6 text-gray-900">
                Clock-In Time
              </label>
              <TimePicker
                placement="bottomRight"
                format="HH:mm"
                style={{ width: "45%" }}
                placeholder="Clock-In Time"
                defaultValue={workLog.clockIn !== "" ? dayjs(workLog.clockIn) : null}
                onChange={workLogClockInChange}
                changeOnBlur={true}
              />
            </div>
            <div className="flex flex-row items-center justify-between w-full mt-2">
              <label htmlFor="clockOut" className="block text-sm font-medium leading-6 text-gray-900">
                Clock-Out Time
              </label>
              <TimePicker
                placement="bottomRight"
                format="HH:mm"
                style={{ width: "45%" }}
                placeholder="Clock-Out Time"
                defaultValue={workLog.clockOut !== "" ? dayjs(workLog.clockOut) : null}
                onChange={workLogClockOutChange}
                changeOnBlur={true}
                disabled={workLog.clockIn === ""}
              />
            </div>
            <div className="flex flex-row items-center justify-between w-full mt-2">
              <label htmlFor="clockOut" className="block text-sm font-medium leading-6 text-gray-900">
                Was the Job Completed?
              </label>
              <Switch
                checked={workLog.jobCompleted}
                onChange={updateJobComplete}
                className={classNames(
                  workLog.jobCompleted ? "bg-blue-600" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-2",
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  className={classNames(
                    workLog.jobCompleted ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
                  )}
                >
                  <span
                    className={classNames(
                      workLog.jobCompleted ? "opacity-0 duration-100 ease-out" : "opacity-100 duration-200 ease-in",
                      "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity",
                    )}
                    aria-hidden="true"
                  >
                    <svg className="w-3 h-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                      <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                  <span
                    className={classNames(
                      workLog.jobCompleted ? "opacity-100 duration-200 ease-in" : "opacity-0 duration-100 ease-out",
                      "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity",
                    )}
                    aria-hidden="true"
                  >
                    <svg className="w-3 h-3 text-blue-600" fill="currentColor" viewBox="0 0 12 12">
                      <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                  </span>
                </span>
              </Switch>
            </div>
            {getLogWorkError() ? (
              getLogWorkError()
            ) : (
              <p className="w-full pt-4 mt-4 text-sm font-medium border-t border-gray-300">
                Hours Worked: <span className="font-semibold">{renderLaborHours(workLog)} hrs</span>
              </p>
            )}
          </div>
          <div className="flex flex-row items-center justify-end w-full gap-2">
            <SecondaryButton label="Cancel" callback={() => closeLogLabor()} />
            <PrimaryButton label="Log Labor" callback={() => submitLogLabor()} />
          </div>
        </div>
      </Drawer>
    );
  };

  const renderAddLaborModal = () => {
    return (
      <Transition.Root show={addLaborModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeAddLaborModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                  <div className="flex flex-col items-start justify-start gap-2">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Add Labor
                    </Dialog.Title>
                    <div key="rate" className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="rate" className="pb-1 text-xs text-gray-600 uppercase">
                        Labor Rate
                      </label>
                      <select
                        className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                        id="rate"
                        defaultValue=""
                        onChange={changeLaborData}
                      >
                        <option hidden disabled value="">
                          Select One
                        </option>
                        {rates.map((rt) => (
                          <option key={rt.rateId} value={rt.rateId}>
                            {rt.laborCode} - {rt.description}
                            {authState.user.functionCategory !== "technician" && ` | ${formatCurrency(rt.rate)}/hr`}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div key="quoted" className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="quoted" className="pb-1 text-xs text-gray-600 uppercase">
                        Hours
                      </label>
                      <input
                        className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                        type="number"
                        id="quoted"
                        placeholder="How many hours of labor is expected?"
                        onChange={changeLaborData}
                      />
                    </div>
                    <div key="laborDescription" className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="laborDescription" className="pb-1 text-xs text-gray-600 uppercase">
                        Labor Description
                      </label>
                      <input
                        className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                        type="text"
                        id="laborDescription"
                        placeholder="Describe the labor being quoted"
                        onChange={changeLaborData}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-5 sm:mt-6">
                    <SecondaryButton label="Cancel" callback={() => closeAddLaborModal()} />
                    <PrimaryButton label="Add Labor" callback={() => submitAddLabor()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const changeLaborData = (e) => {
    let { id, value } = e.target;
    let tmp = addLabor;
    tmp[id] = value;
    setAddLabor(tmp);
  };

  const closeAddLaborModal = () => {
    setAddLaborModal(false);
    setAddLabor({
      rate: "",
      laborDescription: "",
      quoted: "",
    });
  };

  const submitAddLabor = () => {
    let failed = false;
    if (addLabor.rate === "") {
      failed = true;
      toast.error("Please select a labor rate");
    } else if (addLabor.quoted === "") {
      failed = true;
      toast.error("Please enter the estimated number of hours");
    } else if (addLabor.laborDescription === "") {
      failed = true;
      toast.error("Please enter a description of the labor being added");
    }
    if (!failed) {
      setLoading(true);
      AddLaborToAJob(jobId, addLabor)
        .then((res) => {
          setEditLabor(res.data.labor);
          toast.success("Labor entry added successfully");
          closeAddLaborModal();
          GetOneJob(jobId)
            .then((res) => {
              setJob(res.data.job);
              setLocation(res.data.location);
              GetRates()
                .then((res) => {
                  setRates(res.data);
                  GetEmployees()
                    .then((res) => {
                      setEmployees(res.data);
                      setEditLaborModal(true);
                      setTimeout(() => setLoading(false), 700);
                    })
                    .catch((err) => {
                      toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
                      setTimeout(() => {
                        navigate("/jobs");
                      }, 3000);
                    });
                })
                .catch((err) => {
                  toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
                  setTimeout(() => {
                    navigate("/jobs");
                  }, 3000);
                });
            })
            .catch((err) => {
              toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
              setTimeout(() => {
                navigate("/jobs");
              }, 3000);
            });
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error adding a labor entry");
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        });
    }
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
        </div>
      ) : (
        <>
          <Helmet>
            <title>Labor - {job.jobNo} | HTPS ERP</title>
          </Helmet>
          <div className="flex flex-row items-center justify-start w-full mb-3">
            <div className="w-full sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                id="tabs"
                name="tabs"
                className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:border-blue-700 focus:outline-none focus:ring-blue-700 sm:text-sm"
                defaultValue={tabs.find((tab) => tab.current).href}
                onChange={(v) => navigate(`/jobs/${jobId}/${v.target.value}`)}
              >
                {tabs.map((tab) => (
                  <option value={tab.href}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden w-full sm:block">
              <div className="border-b border-gray-200">
                <nav className="flex -mb-px space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <p
                      value={tab.name}
                      key={uuidv4()}
                      onClick={() => navigate(`/jobs/${jobId}/${tab.href}`)}
                      className={classNames(
                        tab.current ? "border-blue-700 text-blue-700" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer",
                      )}
                      aria-current={tab.current ? "page" : undefined}
                    >
                      {tab.name}
                    </p>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-start w-full px-1 mt-1">
            <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
              <p className="text-xl font-bold uppercase">Job Labor</p>
              {job.jobStatus === "open" && <SecondaryButton label="Add Labor" callback={() => setAddLaborModal(true)} />}
            </div>
            <div
              className={`grid w-full ${
                authState.user.functionCategory !== "technician" ? "grid-cols-11" : "grid-cols-3 md:grid-cols-9"
              } gap-4 py-3 border-b border-gray-300`}
            >
              <p className="text-xs font-semibold uppercase">Status</p>
              <p className="hidden text-xs font-semibold uppercase md:inline-flex">Tech</p>
              <p className="hidden text-xs font-semibold uppercase md:inline-flex">Date</p>
              <p className="text-xs font-semibold text-left uppercase md:col-span-3">Description</p>
              <p className="hidden text-xs font-semibold text-center uppercase md:inline-flex">Rate</p>
              <p className="hidden text-xs font-semibold text-right uppercase md:inline-flex">Actual Time</p>
              {authState.user.functionCategory !== "technician" && (
                <>
                  <p className="text-xs font-semibold text-right uppercase">Amount Quoted</p>
                  <p className="text-xs font-semibold text-right uppercase">Amount Billed</p>
                </>
              )}
              <div className="flex flex-row items-center justify-center"></div>
            </div>
            {job.labor.map((lbr, index) => renderLabor(lbr, index))}
          </div>
          {renderEditLaborModal()}
          {renderLogLaborDrawer()}
          {renderAddLaborModal()}
        </>
      )}
    </div>
  );
};

export default JobLabor;
